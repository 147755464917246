import React, { useState } from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { db } from "../../firebase/firebase";

// styles
const divContainer = {
  display: "flex",
  justifyContent: "space-around",
  width: " 100%",
};

const div = {
  display: "flex",
  marginLeft: " 1rem",
};

const KcbWalletViewTransaction = ({
  transactionId,
  transactionData,
}) => {
  const [open, setOpen] = useState(false);
  const [senderCode, setSenderCode] = useState(null);
  const [receipientCode, setReceipientCode] = useState(null);
  const [receipientName, setReceipientName] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    // getSendersMpesaCode(transactionId);
    // getReceipientMpesaCode(transactionId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let accountFrom = "-";
  let accountTo = "-";

  switch (transactionData?.type) {
    case "wallet_to_wallet":
      accountFrom = transactionData?.externalId;
      accountTo = transactionData?.beneficiaryAccount;
      break;
    case "airtimePurchase":
      accountFrom = transactionData?.externalId
      accountTo = transactionData?.externalId
      break;
    case "withdrawal":
      accountFrom = transactionData?.externalId
      accountTo = transactionData?.phoneNumber
      break;
    case "deposit":
      accountFrom = transactionData?.phoneNumber
      accountTo = transactionData?.beneficiaryAccount
      break;
    case "w2pTransfer":
      accountFrom = transactionData?.externalId
      accountTo = transactionData?.phoneNumber
      break;
    case "payment":
      accountFrom = transactionData?.externalId
      // accountTo = transactionData?.phoneNumber
      break;
  
    default:
      accountFrom = "-";
      accountTo = "-";
      break;
  }

  return (
    <Box>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="View"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          View Wallet Transaction
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={divContainer}>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Tracking Code:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {transactionData?.trackingCode ?? '-'}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Transaction Type:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {transactionData?.type}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Amount:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                KES {transactionData?.amount}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Account From:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {accountFrom}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Account To:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {accountTo}
              </p>
            </div>
          </div>

          <div style={{ ...divContainer, marginTop: "2rem" }}>
            {/* <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Sender Mpesa Code:
              </Typography>
              {senderCode ? (
                <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                  {senderCode}
                </p>
              ) : transactionData?.note === "B2C transfer triggered by cron" ? (
                <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                  Triggered by cron
                </p>
              ) : (
                <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                  Not Available
                </p>
              )}
            </div> */}
            {/* <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Receipient Mpesa Code:
              </Typography>
              {receipientCode ? (
                <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                  {receipientCode}
                </p>
              ) : (
                <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                  Not Available
                </p>
              )}
            </div> */}
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Description:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {transactionData?.description}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Phone Number:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {transactionData?.phoneNumber}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Status:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {transactionData?.status}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Final Status:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {transactionData?.finalStatus}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Failure Reason:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {
                  transactionData?.failureReason?.includes('Traceback (most recent call last)')
                    ? 'Failed to complete transaction due to system error.'
                    : transactionData?.failureReason ?? '-'
                }
              </p>
            </div>
          </div>
          
          <div style={{ ...divContainer, marginTop: "2rem" }}>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                MPESA Receipt Number:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {
                  transactionData?.mpesaTransactionMetaData?.MpesaReceiptNumber // deposits
                  ?? 
                  transactionData?.mpesaTransactionMetaData?.TransactionReceipt // withdrawals
                  ?? 
                  '-'
                }
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Wallet Balance:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {
                  transactionData?.waasTransactionMetadata?.balance
                    ? 
                    `KES ${transactionData?.waasTransactionMetadata?.balance}`
                    :
                    '-'
                }
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Date Created:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {moment(transactionData?.createdAt?.toDate())?.format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Date Updated:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {transactionData?.updatedAt 
                  ? moment(transactionData.updatedAt.toDate()).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                  : '-'
              }
              </p>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default KcbWalletViewTransaction;
