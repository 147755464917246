import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box, Button, Stack, Typography } from "@mui/material";
import { EnhancedTransactionToolbar } from './walletTransactionsToolbar';

import CircularStatic from "../../shared/Loader/loader";
import { tableStyles } from "../../styles/tableStyles";

// Update the import to fetch transactions
import { getWalletTransactions } from "../../../apis/waasApi"; 

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

export default function WalletTransactions() {
  // Update state variables to be appropriate for transactions
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const searchRef = useRef(null);

  const handleSearch = async (input) => {
    setIsLoading(true);
    try {
      const fetchedTransactions = await getWalletTransactions(); // Fetch all transactions
      let filteredTransactions;
  
      // Example: Search by transaction ID or account
      filteredTransactions = fetchedTransactions.filter(transaction => 
        transaction.requestId.includes(input) || transaction.account.includes(input)
      );
  
      setTransactions(filteredTransactions); // Update to set transactions
    } catch (error) {
      console.error('Error searching transactions:', error);
    } finally {
      setIsLoading(false);
    }
  };  
  
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    setIsLoading(true);
  
    getWalletTransactions().then(allTransactions => {
      let filteredTransactions = allTransactions;
  
      filters.forEach(filter => {
        const key = columns[filters.indexOf(filter)].name;
        let filterValue = filter[0]; // Assuming single value filters for simplicity
  
        // Adjust this switch statement based on your actual filter keys
        // Example: Filter by transaction status or type
        switch (key) {
          case "status":
            filteredTransactions = filteredTransactions.filter(transaction =>
              transaction.status.toLowerCase().includes(filterValue.toLowerCase())
            );
            break;
          case "type":
            filteredTransactions = filteredTransactions.filter(transaction =>
              transaction.type.includes(filterValue)
            );
            break;
          // Add more cases as necessary for other fields in transactions
          default:
            break;
        }
      });
  
      setTransactions(filteredTransactions);
      setIsLoading(false);
    }).catch(error => {
      console.error('Error applying filters:', error);
      setIsLoading(false);
    });
  };  
  

  const columns = [
    {
      name: "requestId",
      label: "Leja Transaction ID",
      options: {
        filter: false,
      },
    },
    {
      name: "kcbTransactionId",
      label: "KCB Transaction ID",
      options: {
        filter: false,
      },
    },
    {
      name: "account",
      label: "Account From",
      options: {
        filter: true,
      },
    },
    {
      name: "beneficiaryAccount", // Adjust the name based on actual data key
      label: "Account To",
      options: {
        filter: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
      },
    },
    {
      name: "type",
      label: "Transaction Type",
      options: {
        filter: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
      },
    },
    // Additional columns as needed
  ];  
  

  const handleChangePage = async (page) => {
    setIsLoading(true);
    try {
      const fetchedData = await getWalletTransactions(page, rowsPerPage);
      setTransactions(fetchedData.values); // Update based on your API response structure
      setPage(page);
    } catch (error) {
      console.error("Error while fetching page data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleChangeRowsPerPage = async (newRowsPerPage) => {
    setIsLoading(true);
    try {
      const fetchedData = await getWalletTransactions(0, newRowsPerPage);
      setTransactions(fetchedData.values); // Update based on your API response structure
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    } catch (error) {
      console.error("Error while changing rows per page:", error);
    } finally {
      setIsLoading(false);
    }
  };  
  
  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState.page);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",
            "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };  
  
 // pull data from the WAAS API and set table rows
 useEffect(() => {
  const fetchTransactions = async () => {
    setIsLoading(true);
    try {
      // Fetch the first page of transaction data when the component mounts
      const fetchedData = await getWalletTransactions(0, rowsPerPage);
      setTransactions(fetchedData.values); // Use the values array from the response
      setIsLoading(false);
    } catch (err) {
      console.error("Error while fetching transactions", err);
      setIsLoading(false);
    }
  };

  fetchTransactions();
}, [rowsPerPage]);
  
return (
  <div style={{ backgroundColor: "#f2fffb" }}>
    <EnhancedTransactionToolbar
      setSearchInput={setSearchInput}
      searchRef={searchRef}
      handleSearch={() => handleSearch(searchInput)}
    />
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getMuiTheme()}>
        {isLoading ? (
          <CircularStatic />
        ) : (
          <MUIDataTable data={transactions} columns={columns} options={options} />
        )}
      </ThemeProvider>
    </CacheProvider>
  </div>
);
}
