import { STATUS, STATUS_COLORS } from "../constants";

export function getColor(value) {
  switch (value) {
    case STATUS.Disbursed:
      return STATUS_COLORS[STATUS.Disbursed];
    case STATUS.Pending:
      return STATUS_COLORS[STATUS.Pending];
      case STATUS.Requested:
        return STATUS_COLORS[STATUS.Requested];
        case STATUS.Active:
            return STATUS_COLORS[STATUS.Active];
            case STATUS.Defaulted:
                return STATUS_COLORS[STATUS.Defaulted];
                case STATUS.Inactive:
                return STATUS_COLORS[STATUS.Inactive];
                case STATUS.Closed:
                return STATUS_COLORS[STATUS.Closed];
                case STATUS.ApplicationFailed:
                return STATUS_COLORS[STATUS.ApplicationFailed];
                case STATUS.Failed:
                return STATUS_COLORS[STATUS.Failed];
                case STATUS.Rejected:
                return STATUS_COLORS[STATUS.Rejected];
                case STATUS.PartiallyPaid:
                return STATUS_COLORS[STATUS.PartiallyPaid];
                case STATUS.PendingDisbursement:
                return STATUS_COLORS[STATUS.PendingDisbursement];
                case STATUS.Cancelled:
                return STATUS_COLORS[STATUS.Cancelled];
                case STATUS.Completed:
                return STATUS_COLORS[STATUS.Completed];
                
    default:
      return {};
  }
}
