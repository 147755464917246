import React from "react";
import { Stack, Typography, Box } from "@mui/material";

export default function CommonTableCell({
  tableContent,
  tableSubContent,
  icon,
}) {
  return (
    <Box>
      <Stack direction="row" alignItems="center">
        {icon && (
          <img
            src={icon}
            alt="icon"
            style={{ height: "24px", width: "24px", marginRight: "8px" }}
          />
        )}
        <Box>
          <Typography
            variant="body2"
            fontWeight="500"
            sx={{ color: "#49454F", fontSize: "12px", fontWeight: 500 }}
          >
            {tableContent ?? "-"}
          </Typography>
          <Typography
            variant="caption"
            fontWeight="300"
            sx={{ color: "#807e81", fontSize: "12px" }}
          >
            {tableSubContent}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export const CommonTableCellLight = ({
  tableContent,
  tableSubContent,
  icon,
}) => {
  return (
    <Box>
      <Stack direction="column">
        <Box display="flex" alignItems="center">
          {icon && (
            <img
              src={icon}
              alt="icon"
              style={{ height: "24px", width: "24px", marginRight: "8px" }}
            />
          )}
          <Typography variant="body2" fontWeight="500" color="textPrimary">
            {tableContent ?? "-"}
          </Typography>
        </Box>
        <Typography variant="caption" color="textSecondary">
          {tableSubContent}
        </Typography>
      </Stack>
    </Box>
  );
};

export const TableTitle = ({ text, variant }) => (
  <Typography
    variant={variant}
    style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "Center",
      color: "gray",
      fontSize: "16px",
      fontWeight: 600,
      marginBottom: "5px",
    }}
  >
    {text}
  </Typography>
);
