import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider, Field } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DoneIcon from "@mui/icons-material/Done";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { KYCOperations } from "../../../firebase/operations";

export default function VerifyKYC({ businessData, businessId }) {
  const [open, setOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const InputFeedback = ({ error }) =>
    error ? (
      <Typography
        color="error"
        sx={{ fontSize: "0.8rem", fontFamily: "Rubik, sans-serif" }}
      >
        {error}
      </Typography>
    ) : null;

  const VerifyBusinessSchema = Yup.object().shape({
    businessName: Yup.boolean(),
    email: Yup.boolean(),
    photo: Yup.boolean(),
    userId: Yup.boolean(),
    location: Yup.boolean(),
    businessType: Yup.boolean(),
    businessPermit: Yup.boolean(),
    businessActivationCode: Yup.boolean(),
    businessID: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      businessName: false,
      email: false,
      photo: false,
      userId: false,
      location: false,
      businessType: false,
      businessPermit: false,
      businessActivationCode: false,
      businessID: false,
    },
    validationSchema: VerifyBusinessSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await KYCOperations.verifyKYC(values, businessId);

      if (response.verified) {
        setIsVerified(true);
        setOpenSnackbar(true);
        resetForm();
        handleClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setErrorMsg(response.error);
        setOpenSnackbar(true);
      }
    },
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isVerified ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isVerified
            ? "KYC Verified successfully!!!"
            : "Cannot Verify KYC: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <DoneIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Verify Business"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{
            fontFamily: "Rubik, sans-serif",
            paddingBottom: "0",
          }}
        >
          Verify {businessData.businessName}'s Business
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Typography
                sx={{
                  fontStyle: "italic",
                  color: "red",
                  fontFamily: "Rubik, sans-serif",
                  marginBottom: "1.3rem",
                  fontSize: ".92rem",
                  maxWidth: "80%",
                }}
              >
                ***IMPORTANT - Kindly verify that the data below is available
                before you check each box{" "}
              </Typography>
              <div
                role="group"
                aria-labelledby="checkbox-group"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label>
                  <Field type="checkbox" name="businessName" />
                  Business Name
                </label>
                {touched.businessName && (
                  <InputFeedback error={errors.businessName} />
                )}
                <label>
                  <Field type="checkbox" name="email" />
                  Email
                </label>
                {touched.email && <InputFeedback error={errors.email} />}
                <label>
                  <Field type="checkbox" name="userId" />
                  User ID (Both Front and Back Side)
                </label>
                {touched.userId && <InputFeedback error={errors.userId} />}
                <label>
                  <Field type="checkbox" name="photo" />
                  User Photo
                </label>
                {touched.photo && <InputFeedback error={errors.photo} />}
                <label>
                  <Field type="checkbox" name="location" />
                  Business Location
                </label>
                {touched.location && <InputFeedback error={errors.location} />}
                <label>
                  <Field type="checkbox" name="businessType" />
                  Business Type
                </label>
                {touched.businessType && (
                  <InputFeedback error={errors.businessType} />
                )}
                <label>
                  <Field type="checkbox" name="businessPermit" />
                  Business Permit
                </label>
                {touched.businessPermit && (
                  <InputFeedback error={errors.businessPermit} />
                )}
                <label>
                  <Field type="checkbox" name="businessActivationCode" />
                  Business Activation Code
                </label>
                {touched.businessActivationCode && (
                  <InputFeedback error={errors.businessActivationCode} />
                )}
                <label>
                  <Field type="checkbox" name="businessID" />
                  Business ID
                </label>
                {touched.businessID && (
                  <InputFeedback error={errors.businessID} />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ fontFamily: "Rubik, sans-serif" }}
                variant="outlined"
                color="error"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{ fontFamily: "Rubik, sans-serif" }}
                variant="contained"
                type="submit"
                color="success"
              >
                Verify
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
