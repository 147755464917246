import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Geocode from "react-geocode";

import { db } from "../../../firebase/firebase";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box, Button, Stack, Typography } from "@mui/material";

// import { EnhancedTableToolbar } from "./usersToolbar";
import CircularStatic from "../../shared/Loader/loader";

import { tableStyles } from "../../styles/tableStyles";
import NoData from "../../shared/NoData/noData";

import KYCMoreMenu from "./kycMoreMenu";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

// table stylings
const getMuiTheme = () => createTheme(tableStyles);

function KnowYourCustomer({ userData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [updatedTo, setUpdatedTo] = useState(null);
  const [updatedFrom, setUpdatedFrom] = useState(null);
  const [location, setLocation] = useState("");

  let rows = [];

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    console.log("Filters: ", filters);
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      console.log("Key: ", key);
      let newFilter;
      if (key === "createdAt") {
        newFilter = {
          createdFrom: moment(item[0]).format("DD-MM-YYYY"),
          createdTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else if (key === "updatedAt") {
        newFilter = {
          updatedTo: moment(item[0]).format("DD-MM-YYYY"),
          updatedFrom: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else {
        newFilter = { [key]: moment(item[0]).format("DD-MM-YYYY") };
      }
      console.log("FILTER: ", newFilter);
      Object.assign(filterList, newFilter);
    });
    console.log("FILTER LIST: ", filterList);

    setIsFiltered(true);
  };

  const columns = [
    {
      name: "businessName",
      label: "Business Name",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) =>
          rows[dataIndex].businessName
            ? rows[dataIndex].businessName
            : "Not Provided",
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
      },
    },
    {
      name: "businessType",
      label: "Business Type",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) =>
          rows[dataIndex].businessType
            ? rows[dataIndex].businessType
            : "Not Provided",
      },
    },
    {
      name: "businessIndustry",
      label: "Business Industry",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) =>
          rows[dataIndex].businessIndustry
            ? rows[dataIndex].businessIndustry
            : "Not Provided",
      },
    },
    {
      name: "status",
      label: "KYC Status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].status === "level_0") {
            return (
              <Typography
                sx={{ fontSize: "0.85rem", fontFamily: "Rubik, sans-serif" }}
              >
                Level 0: Nothing Submitted
              </Typography>
            );
          }
          if (rows[dataIndex].status === "level_1") {
            return (
              <Typography
                sx={{ fontSize: "0.85rem", fontFamily: "Rubik, sans-serif" }}
              >
                Level 1: Only One Item Submitted
              </Typography>
            );
          }
          if (rows[dataIndex].status === "level_2") {
            return (
              <Typography
                sx={{ fontSize: "0.85rem", fontFamily: "Rubik, sans-serif" }}
              >
                Level 2: Only Two Items Submitted
              </Typography>
            );
          }
          if (rows[dataIndex].status === "level_3") {
            return (
              <Typography
                sx={{ fontSize: "0.85rem", fontFamily: "Rubik, sans-serif" }}
              >
                Level 3: All Items Submitted
              </Typography>
            );
          }
          if (rows[dataIndex].status === "level_4") {
            return (
              <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
                Level 4: Verified
              </Typography>
            );
          }
          if (rows[dataIndex].status === null) {
            return (
              <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
                Level 0: Nothing Submitted
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "businessVerificationStatus",
      label: "Verification Status",
      options: {
        filter: false,
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Created From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Created To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Created From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Created To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(createdAt, filters) {
            const formattedDate =
              typeof createdAt === "string"
                ? moment(createdAt).format("MM-DD-YYYY")
                : moment(createdAt?.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );

            // console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created From:"
                  value={createdFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setCreatedFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created To:"
                  value={createdTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setCreatedTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) =>
          typeof rows[dataIndex].createdAt === "string"
            ? moment(rows[dataIndex].createdAt).format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : moment(rows[dataIndex].createdAt?.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              ),
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) =>
          rows[dataIndex].location
            ? typeof rows[dataIndex].location === "string"
              ? rows[dataIndex].location
              : location
            : "Not Provided",
      },
    },
    {
      name: "updatedAt",
      label: "Updated At",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Updated From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Updated To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Updated From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Updated To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(updatedAt, filters) {
            const formattedDate =
              typeof updatedAt === "string"
                ? moment(updatedAt).format("MM-DD-YYYY")
                : moment(updatedAt.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );

            // console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated From:"
                  value={updatedFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setUpdatedFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated To:"
                  value={updatedTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setUpdatedTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) =>
          typeof rows[dataIndex].updatedAt === "string"
            ? moment(rows[dataIndex].updatedAt).format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : moment(rows[dataIndex].updatedAt?.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex]) {
            return (
              <KYCMoreMenu
                businessId={rows[dataIndex]["docId"]}
                businessData={rows[dataIndex]}
                refresh={() => {}}
              />
            );
          }
        },
      },
    },
  ];

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [5, 10],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };

  useEffect(() => {
    const fetchMerchantBusiness = async () => {
      const businessRef = collection(db, "merchant_businesses");

      //query merchant business with user id
      const merchantBusiness = query(
        businessRef,
        where("phoneNumber", "==", userData.phoneNumber)
      );

      const businessSnapshot = await getDocs(merchantBusiness);

      businessSnapshot.size < 1
        ? setIsLoading(false)
        : businessSnapshot.forEach((doc) => {
            rows.push({ ...doc.data(), docId: doc.id });
            setIsLoading(false);
          });
    };

    fetchMerchantBusiness();

    // get business location
    const getLocation = async (businessData) => {
      const { location } = businessData;
      await Geocode.fromLatLng(location?.latitude, location?.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log("ADDRESS: " + address);
          setLocation(address);
        },
        (error) => {
          console.log("Error while getting location: ", error);
        }
      );
    };

    getLocation();
  }, []);

  console.log("User Merchant Businesses: ", rows);

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <Box>
        <Typography
          sx={{
            backgroundColor: "white",
            fontSize: "1.1rem",
            fontWeight: "bold",
            marginBottom: "0.8rem",
            fontFamily: "Rubik, sans-serif",
          }}
        >{`${userData.firstName} ${userData.lastName}'s Businesses`}</Typography>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme()}>
            {isLoading ? (
              <CircularStatic />
            ) : rows.length < 1 ? (
              <NoData text="No Business Details Found!" />
            ) : (
              <MUIDataTable data={rows} columns={columns} options={options} />
            )}
          </ThemeProvider>
        </CacheProvider>
      </Box>
    </div>
  );
}

export default KnowYourCustomer;
