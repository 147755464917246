import { useRef, useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Menu, IconButton } from "@mui/material";
import KcbWalletViewTransaction from "./KcbWalletViewTransaction";
import KcbWalletManualDisbursement from "./KcbWalletManualDisbursement";
import { KCBWalletTxnType } from "../../utils/kcbWallet/KCBWalletTxnType";
import { KcbWalletTxnStatus } from "../../utils/kcbWallet/KcbWalletTxnStatus";

const KcbWalletMoreMenu = ({ transactionId, transactionData }) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const defaultPendingStatuses = ["pending"];

  const allowManualDisbursement = () => {
    const isWaasSuccess = transactionData?.status === KcbWalletTxnStatus.waas_success;
    const isCallbackSuccess = transactionData?.status === KcbWalletTxnStatus.callback_success;
    const isStkPushSent = transactionData?.status === KcbWalletTxnStatus.stk_push_sent;
    const isFinalStatusPending = transactionData?.finalStatus === KcbWalletTxnStatus.pending;
    const isManuaTransfer = transactionData?.status === KcbWalletTxnStatus.manual_transfer;
    // TODO: Allow for retrying failed/still pending 'Manual Transfers' (take note of infinite loops on the backend)

    switch (transactionData?.type) {
      case KCBWalletTxnType.withdrawal:
        // TODO: handle status where B2C callback fails to be sent from Safaricom after successful B2C hit on the backend 
        // const isB2CRequestFailed = transactionData?.finalStatus === KcbWalletTxnStatus.b2c_request_failed;
        // const isFinalStatusFailed = transactionData?.finalStatus === KcbWalletTxnStatus.failed;
        return isWaasSuccess && isFinalStatusPending;
      case KCBWalletTxnType.w2pTransfer:
        return isWaasSuccess && isFinalStatusPending;
      case KCBWalletTxnType.airtimePurchase:
        return isWaasSuccess && isFinalStatusPending;
      case KCBWalletTxnType.deposit:
        return (isCallbackSuccess || isStkPushSent) && isFinalStatusPending;

      // TODO: add logic for the other transaction types

      default:
        return defaultPendingStatuses.includes(transactionData?.finalStatus);
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <KcbWalletViewTransaction
          transactionId={transactionId}
          transactionData={transactionData}
        />
        {
          allowManualDisbursement()
          && (
            <KcbWalletManualDisbursement 
              transactionId={transactionId}
              transactionData={transactionData}
            />
          )
        }
      </Menu>
    </>
  )
};

export default KcbWalletMoreMenu;
