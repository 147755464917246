import { doc, updateDoc } from "firebase/firestore";
import { KCB_WALLET_TRANSACTIONS } from "../../utils/kcbWallet/collections";
import { db } from "../firebase";
import { transactionStatus } from "../../utils/transferStatuses";
import { Pagination } from "../operations";

export const kcbWalletManualTransactions = {
  // manually complete pending withdrawals 
  completePendingWithdrawal: async (transactionId) => {
    const transactionRef = doc(db, KCB_WALLET_TRANSACTIONS, transactionId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));

    let isTransferred = false;

    try {
      await updateDoc(
        transactionRef,
        {
          manuallyProcessedBy: sessionUser?.user.uid,
          status: transactionStatus.MANUAL_TRANSFER,
        }
      );

      console.log("Withdrawal transaction completion request submitted successfully!");

      isTransferred = true;

      await Pagination.fetchFirstBatch(
        KCB_WALLET_TRANSACTIONS,
        "createdAt"
      );

      return { isTransferred, err: null };
    } catch (err) {
      console.log("Error completing pending withdrawal :: ", err);
      return { isTransferred, err };
    }
  },
};

