import React, { useEffect, useState } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography, TextField, Box, Button, Stack } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { Pagination } from "../../../firebase/operations";

import { EnhancedTableToolbar } from "./paymentsToolbar";
import CircularStatic from "../../shared/Loader/loader";

import { tableStyles } from "../../styles/tableStyles";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

export default function Payments() {
  const [rows, setRows] = useState([]); // table rows
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [requestDateFrom, setRequestDateFrom] = useState(null);
  const [requestDateTo, setRequestDateTo] = useState(null);

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    console.log("Filters: ", filters);
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      let newFilter;
      if (key === "dateRequested") {
        newFilter = {
          requestDateFrom: moment(item[0]).format("DD-MM-YYYY"),
          requestDateTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else {
        newFilter = { [key]: moment(item[0]).format("DD-MM-YYYY") };
      }
      Object.assign(filterList, newFilter);
    });
    console.log("FILTER LIST: ", filterList);
    setIsFiltered(true);
  };

  const columns = [
    {
      name: "displayName",
      label: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,

        customBodyRenderLite: (dataIndex) => `KES ${rows[dataIndex].amount}`,
      },
    },
    {
      name: "deviceInfo",
      label: "Source",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if (typeof rows[dataIndex].deviceInfo === "string") {
            return (
              <Typography
                sx={{ fontSize: "0.9rem", fontFamily: "Rubik, sans-serif" }}
              >
                USSD
              </Typography>
            );
          }

          return (
            <Typography
              sx={{ fontSize: "0.9rem", fontFamily: "Rubik, sans-serif" }}
            >
              Mobile App
            </Typography>
          );
        },
      },
    },
    {
      name: "businessNumber",
      label: "Business Number",
      options: {
        filter: false,
      },
    },
    {
      name: "dateRequested",
      label: "Date Requested",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Requested From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Requested To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Requested From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Requested To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(dateRequested, filters) {
            const formattedDate =
              typeof dateRequested === "string"
                ? moment(dateRequested, "DD-MM-YYYY").format("MM-DD-YYYY")
                : moment(dateRequested.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );

            // console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Requested From:"
                  value={requestDateFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setRequestDateFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Requested To:"
                  value={requestDateTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setRequestDateTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) => {
          return typeof rows[dataIndex].dateRequested === "string"
            ? moment(rows[dataIndex].dateRequested, "DD-MM-YYYY").format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : moment(rows[dataIndex].dateRequested.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              );
        },
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,

        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].type === "till") {
            return (
              <Typography
                sx={{
                  color: "#009966",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0,153,102,.2)",
                  padding: ".2rem",
                  width: "85%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#009966" }} />
                </span>{" "}
                Till
              </Typography>
            );
          } else if (rows[dataIndex].type === "paybill") {
            return (
              <Typography
                sx={{
                  color: "#0000FF",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0,0,255,.2)",
                  padding: ".2rem",
                  width: "85%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#0000FF" }} />
                </span>{" "}
                Paybill
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,

        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].status === "cancelled") {
            return (
              <Typography
                sx={{
                  color: "red",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(255,0,0,.2)",
                  padding: ".2rem",
                  width: "85%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem" }} />
                </span>
                Cancelled
              </Typography>
            );
          } else if (rows[dataIndex].status === "approved") {
            return (
              <Typography
                sx={{
                  color: "#4B6043",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(75,96,67,.2)",
                  padding: ".2rem",
                  width: "85%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#4B6043" }} />
                </span>{" "}
                Approved
              </Typography>
            );
          } else if (rows[dataIndex].status === "failed") {
            return (
              <Typography
                sx={{
                  color: "#C30010",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(95,0,16,.2)",
                  padding: ".2rem",
                  width: "85%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#C30010" }} />
                </span>{" "}
                Failed
              </Typography>
            );
          } else if (rows[dataIndex].status === "sent") {
            return (
              <Typography
                sx={{
                  color: "#3BB143",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(59,177,67,.2)",
                  padding: ".2rem",
                  width: "85%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#3BB143" }} />
                </span>{" "}
                Sent
              </Typography>
            );
          } else {
            return (
              <Typography
                sx={{
                  color: "#AA5B00",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(170,91,0,.2)",
                  padding: ".2rem",
                  width: "85%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem" }} />
                </span>{" "}
                Pending
              </Typography>
            );
          }
        },
      },
    },
  ];

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);

    const fetchMoreData = (key) => {
      if (key) {
        Pagination.fetchNextBatch(key, "paybill_transactions", "dateRequested")
          .then((res) => {
            setLastKey(res.lastKey);
            setRows(rows.concat(res.fetchedData));
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("Err: ", err);
          });
      }
    };

    fetchMoreData(lastKey);
    console.log("last key:", lastKey);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };

  // pull data from firebase and set table rows
  useEffect(() => {
    Pagination.fetchFirstBatch("paybill_transactions", "dateRequested")
      .then((res) => {
        setRows(res.fetchedData);
        setLastKey(res.lastKey);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  console.log("send_money_transactions: ", rows);

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <EnhancedTableToolbar />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MUIDataTable data={rows} columns={columns} options={options} />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
