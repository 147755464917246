import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import Radio from '@mui/material/Radio';
import { ArrowDropDown, ChevronRight, TuneOutlined } from '@mui/icons-material';
import { COLORS } from '../../constants';
import CustomButton from '../Buttons/CustomButton';
import { Divider } from '@mui/material';

const MenuButton = ({
    text = '',
    nestedOptions = [],
    onMainSelect,
    startIcon,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
    const [selectedMain, setSelectedMain] = useState(null);
    const [selectedNested, setSelectedNested] = useState(null);

    const open = Boolean(anchorEl);
    const nestedOpen = Boolean(nestedAnchorEl);

    const handleMainClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNestedClick = (event, mainOption) => {
        setSelectedMain(mainOption);
        setNestedAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setNestedAnchorEl(null);
    };


    const handleNestedSelect = (nestedOption) => {
        setSelectedNested(nestedOption);
        handleClose();
        if (onMainSelect) {
            onMainSelect(selectedMain, nestedOption);
        }
    };

    return (
        <div  sx={{width:'100%',marginX:'30px'}}>
            <CustomButton
             text={text}
             startIcon={startIcon}
             endIcon={<ArrowDropDown style={{marginLeft:'15px'}}/>}
             onClick={handleMainClick}
             open={anchorEl}
             color={COLORS.lightGreen}
             textColor={COLORS.darkgreen}

            />
          
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
                slotProps={{
                    paper: {
                      style: { width: anchorEl ? anchorEl.clientWidth : 'auto' }
                    }
                  }}
            >
                {nestedOptions.map((option) => (<>
                    <MenuItem
                        key={option.main}
                       style={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                        
                       }}
                       
                        onClick={(event) => handleNestedClick(event, option.main)}
                    >

                      
                        {option.main}
                        <ChevronRight/>
                    </MenuItem>
                    <Divider sx={{color:COLORS.lightGray}}/>
                    </>
                    
                ))}
            </Menu>
            {selectedMain && nestedOptions.find(option => option.main === selectedMain) && (
                <Menu
                    id="nested-menu"
                    anchorEl={nestedAnchorEl}
                    open={nestedOpen}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'menu-button',
                    }}
                    slotProps={{
                        paper: {
                          style: { width: anchorEl ? anchorEl.clientWidth : 'auto' }
                        }
                      }}
                >
                    {nestedOptions
                        .find((option) => option.main === selectedMain)
                        .nested.map((nestedOption) => (
                            <MenuItem
                                key={nestedOption}
                                onClick={() => handleNestedSelect(nestedOption)}
                            >
                                <Radio checked={nestedOption === selectedNested} />
                                {nestedOption}
                            </MenuItem>
                        ))}
                </Menu>
            )}
        </div>
    );
};

export default MenuButton;
