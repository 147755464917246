// utils/axiosInstance.ts
import axios from "axios";
import { authHeader } from "./authHelper";


const instance = axios.create({
  baseURL: process.env.REACT_APP_AWS_BASEURL,
  timeout: 150000,
  maxBodyLength: Infinity
});

const responseBody = (response) => response;

const responseError = async (error) => {
  return Promise.reject(error);
};

export const requests = {
//   get: (url, token, headers) =>
   
//     instance
//       .get(url, {
//         headers: { ...authHeader(token), ...headers }
//       })
//       .then(responseBody)
//       .catch(responseError),
get: (url, token, headers) => {
    const authHeaders = authHeader(token);
    return instance
      .get(url, {
        headers: { ...authHeaders, ...headers },
      })
      .then(responseBody)
      .catch(responseError);
  },

  getWithParams: (url, token, config={}) =>
    instance
      .get(url, { params: config, headers: authHeader(token) })
      .then(responseBody)
      .catch(responseError),

  post: (url, body, token, headers) =>
    instance
      .post(url, body, {
        headers: { ...authHeader(token), ...headers },
        maxBodyLength: Infinity,
        maxContentLength: Infinity
      })
      .then(responseBody)
      .catch(responseError),

  put: (url, body, token, headers) =>
    instance
      .put(url, body, {
        headers: { ...authHeader(token), ...headers }
      })
      .then(responseBody)
      .catch(responseError),

  patch: (url, body, token, config) =>
    instance
      .patch(url, body, { ...config, headers: authHeader(token) })
      .then(responseBody)
      .catch(responseError),

  delete: (url, token, headers) =>
    instance
      .delete(url, {
        headers: { ...authHeader(token), ...headers }
      })
      .then(responseBody)
      .catch(responseError)
};

export default instance;
