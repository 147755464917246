import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import { KCBWalletTxnType } from "../../utils/kcbWallet/KCBWalletTxnType";
import { kcbWalletManualTransactions } from "../../firebase/kcbWalletTransactions/operations";

const KcbWalletManualDisbursement = ({ transactionId, transactionData }) => {
  const [open, setOpen] = useState(false);
  const [isTransferred, setIsTransferred] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  let dialogTitleText = ""
  let dialogBody = "";
  let agreeActionText = "Transfer"
  let cancelActionText = "Close"

  switch (transactionData?.type) {
    case KCBWalletTxnType.deposit:
      dialogTitleText = "Manual Deposit";
      dialogBody = (
        <Typography>
          Are you sure you want to manually complete the deposit of {" "}
          <span style={{ fontWeight: "bold" }}>
            KES {transactionData.amount}
          </span> {" "}
          from {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.phoneNumber}
          </span>{" "}
          to wallet ID {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.beneficiaryAccount}
          </span>
        </Typography>
      );
      break;
    case KCBWalletTxnType.withdrawal:
      dialogTitleText = "Manually withdrawal";
      dialogBody = (
        <Typography>
          Are you sure you want to manually complete the withdrawal of {" "}
          <span style={{ fontWeight: "bold" }}>
            KES {transactionData.amount}
          </span> {" "}
          from wallet ID {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.externalId}
          </span>{" "}
          to {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.phoneNumber}
          </span>
        </Typography>
      );
      break;
    case KCBWalletTxnType.w2wTransfer:
      dialogTitleText = "Manual Wallet to Wallet";
      dialogBody = (
        <Typography>
          Are you sure you want to manually complete the wallet to wallet transfer of {" "}
          <span style={{ fontWeight: "bold" }}>
            KES {transactionData.amount}
          </span> {" "}
          from wallet ID {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.externalId}
          </span>{" "}
          to wallet ID {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.beneficiaryAccount}
          </span>
        </Typography>
      );
      break;
    case KCBWalletTxnType.airtimePurchase:
      dialogTitleText = "Manual Airtime Purchase";
      dialogBody = (
        <Typography>
          Are you sure you want to manually complete airtime purchase of {" "}
          <span style={{ fontWeight: "bold" }}>
            KES {transactionData.amount}
          </span> {" "}
          from wallet ID {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.externalId}
          </span>{" "}
          to {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.phoneNumber}
          </span>
        </Typography>
      );
      break;
    case KCBWalletTxnType.w2pTransfer:
      dialogTitleText = "Manual Wallet to Person";
      dialogBody = (
        <Typography>
          Are you sure you want to manually complete wallet to person transaction of {" "}
          <span style={{ fontWeight: "bold" }}>
            KES {transactionData.amount}
          </span> {" "}
          from wallet ID {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.externalId}
          </span>{" "}
          to {" "}
          <span style={{ fontWeight: "bold" }}>
            {transactionData.phoneNumber}
          </span>
        </Typography>
      );
      break;
    case KCBWalletTxnType.reversal:
      dialogTitleText = "Manual Reversal";
      dialogBody = (
        <Typography>
          Are you sure you want to manually complete this reversal of {" "}
          <span style={{ fontWeight: "bold" }}>
            KES {transactionData.amount}
          </span>
        </Typography>
      );
      break;
    case KCBWalletTxnType.payment:
      dialogTitleText = "Manual Payment";
      dialogBody = (
        <Typography>
          Are you sure you want to manually complete this payment of {" "}
          <span style={{ fontWeight: "bold" }}>
            KES {transactionData.amount}
          </span>
        </Typography>
      );
      break;

    default:
      dialogTitleText = "";
      dialogBody = "";
      break;
  }

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const [isInitiatingManualDisbursement, setIsInitiatingManualDisbursement] = useState(false);

  const handleManualDisbursement = async () => {
    try {
      setIsInitiatingManualDisbursement(true);

      const response = await kcbWalletManualTransactions.completePendingWithdrawal(transactionId);

      console.log("RESPONSE: ", response);

      if (response.isTransferred) {
        handleClose();
        setIsTransferred(true);
        setOpenSnackbar(true);
      } else {
        setErrorMsg(response.err ?? "An error occurred");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.log("An error occurred ::", error);

      setErrorMsg(error);
      setOpenSnackbar(true);
    } finally {
      setIsInitiatingManualDisbursement(false);
    }
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isTransferred ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isTransferred
            ? "Manual Disbursement made successfully!!!"
            : "Cannot Manually Disbursement transaction: " + errorMsg}
        </Alert>
      </Snackbar>

      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <SendToMobileIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Disburse Manually"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        maxWidth="xl"
      >
        <DialogTitle sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          {dialogTitleText}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {dialogBody}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {cancelActionText}
          </Button>

          <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handleManualDisbursement}
            disabled={isInitiatingManualDisbursement}
          >
            {
              isInitiatingManualDisbursement 
                ? <CircularProgress size={24} color="success" />
                : agreeActionText 
            }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KcbWalletManualDisbursement;
