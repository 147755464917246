import React from 'react';
import Button from '@mui/material/Button';

import { BUTTON_VARIANTS, COLORS } from '../../constants';

const CustomButton = ({
  id = "menu-button",
  open = false,
  size = 'medium',
  variant = BUTTON_VARIANTS.contained,
  color = COLORS.darkgreen,
  startIcon = null ,
  endIcon = null,
  text = '',
  onClick,
  backgroundColor = variant === BUTTON_VARIANTS.contained ? color : 'transparent',
  textColor = variant === BUTTON_VARIANTS.contained && color ? 'white' : color,
  borderColor = color,
  style = {},
}) => {
  return (
    <Button
      id={id}
      aria-controls={open ? 'menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={onClick}
      size={size}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      style={{
        backgroundColor: variant === BUTTON_VARIANTS.contained ? backgroundColor : 'transparent',
        color: textColor,
        marginY: 3,
        marginX: '15px',
        ...(size === 'small' && { fontSize: '0.75rem' }),
        ...(size === 'large' && { fontSize: '1.25rem' }),
        ...(variant === BUTTON_VARIANTS.outlined && { border: `1px solid ${borderColor}` }),
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
