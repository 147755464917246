import { ACCESS_TOKEN, getCookie, setCookie } from '../utils/CookiesHandler'
import { useState } from "react";

const useToken = () => {
  const getToken = () => {
    const tokenString = getCookie(ACCESS_TOKEN);
    const userToken = tokenString;
    return userToken || undefined;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    setCookie(ACCESS_TOKEN, userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  };
};

export default useToken;
