import { useMutation } from "@tanstack/react-query";
import { requests } from "../helper/apiHelper";
import { TOKEN } from "./endpoints";

export async function fetchToken() {
    const response = await requests.post(TOKEN);
    return response;
}
export const useFetchToken= () => {
    return useMutation({
      mutationFn: fetchToken,
      onError: ({ response }) => {
        throw new Error(response);
      }
    });
  };