import React from 'react';
import Chip from '@mui/material/Chip';
import { styled, useTheme } from '@mui/material/styles'; 

const StyledChip = styled(Chip)(({ theme, bgColor, color }) => ({
  backgroundColor: bgColor,
  color: color,
  fontWeight: 600,
  fontSize: '12px',
  padding: '2.5px 12px',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
}));

export default function StatusChip({ value, color, bgColor, onClick }) {
  const theme = useTheme(); 

  return (
    <StyledChip
      label={value}
      variant="outlined"
      onClick={onClick}
      style={{ backgroundColor: bgColor, color: color ,border:`1px solid ${color}`}} 
      theme={theme} 
    />
  );
}