import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { collection, query, where, getDocs } from "firebase/firestore";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Button, Tooltip, Grid } from "@mui/material";

import { Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { Pagination } from "../../firebase/operations";

import CircularStatic from "../shared/Loader/loader";

import { tableStyles } from "../styles/tableStyles";

import { db } from "../../firebase/firebase";
import KcbWalletToolbar from "./KcbWalletToolbar";
import KcbWalletMoreMenu from "./KcbWalletMoreMenu";
import { DateTimePicker, pickersLayoutClasses } from "@mui/x-date-pickers";
import { KCB_WALLET_TRANSACTIONS } from "../../utils/kcbWallet/collections";

const midnightOfToday = () => new Date(new Date().setHours(0, 0, 0, 0));
const dateTimeFormat = "MM-DD-YYYY, HH:mm";
const dateTimeFormat2 = "MM-DD-YYYY HH:mm";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

const KcbWalletTransactions = () => {
  const [rows, setRows] = useState([]); // table rows
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [requestDateFrom, setRequestDateFrom] = useState(null);
  const [requestDateTo, setRequestDateTo] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const searchRef = useRef(null);

  const columns = [
    // tracking code
    {
      name: "trackingCode",
      label: "Tracking Code",
      options: {
        filter: false,
      },
    },
    // transaction type
    {
      name: "type",
      label: "Transaction Type",
      options: {
        filter: true,
      },
    },
    // account from
    {
      name: "txnDocId",
      label: "Account From",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const externalId = rows[dataIndex]?.externalId;
          const phoneNumber = rows[dataIndex]?.phoneNumber;
          const trxType = rows[dataIndex]?.type;

          switch (trxType) {
            case "wallet_to_wallet":
              return externalId
            case "airtimePurchase":
              return externalId
            case "withdrawal":
              return externalId
            case "deposit":
              return phoneNumber
            case "w2pTransfer":
              return externalId
            case "payment":
              return externalId

            default:
              return "";
          }
        },
      },
    },
    // account to 
    {
      name: "beneficiaryAccount",
      label: "Account To",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const beneficiaryAccount = rows[dataIndex]?.beneficiaryAccount;
          const phoneNumber = rows[dataIndex]?.phoneNumber;
          const trxType = rows[dataIndex]?.type;

          switch (trxType) {
            case "deposit":
              return beneficiaryAccount
            case "wallet_to_wallet":
              return beneficiaryAccount
            case "withdrawal":
              return phoneNumber
            case "airtimePurchase":
              return phoneNumber
            case "w2pTransfer":
              return phoneNumber

            default:
              return "";
          }
        },
      },
    },
    // amount
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => `KES ${rows[dataIndex].amount}`,
      },
    },
    // charges
    {
      name: "charge",
      label: "Charges",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const isFinalStatusSuccess = rows[dataIndex]?.finalStatus === "success";
          if (!isFinalStatusSuccess) {
            return "-"
          }

          return `KES ${rows[dataIndex]?.charge ?? "0"}`;
        },
      },
    },
    // status
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if (["cancelled"].includes(rows[dataIndex].status)) {
            return (
              <Tooltip
                title="Transaction has failed checks or manually cancelled."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "red",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(255,0,0,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "red" }} />
                  </span>
                  {rows[dataIndex].status}
                </Typography>
              </Tooltip>
            );
          } else if (["success", "pinless_success", "waas_success", "callback_success", "at_success"].includes(rows[dataIndex].status)) {
            return (
              <Tooltip
                title=""
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#009966",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0,153,102,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#009966" }} />
                  </span>{" "}
                  {rows[dataIndex].status}
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "approved") {
            return (
              <Tooltip title="Transaction passed all tests." placement="bottom">
                <Typography
                  sx={{
                    color: "#4B6043",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(75,96,67,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#4B6043" }} />
                  </span>{" "}
                  Approved
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "manual_sent") {
            return (
              <Tooltip
                title="Status has been manually changed."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#03C04A",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(3,192,74,.2)",
                    padding: ".2rem",
                    width: "98%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#03C04A" }} />
                  </span>{" "}
                  Manual Sent
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "sent") {
            return (
              <Tooltip
                title="Money received by recipient. B2C Callback came back."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#3BB143",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(59,177,67,.2)",
                    padding: ".2rem",
                    width: "85%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#3BB143" }} />
                  </span>{" "}
                  Sent
                </Typography>
              </Tooltip>
            );
          } else if (["failed", "waas_failed", "callback_failed", "pinless_failed", "b2c_request_failed", "at_failed"].includes(rows[dataIndex].status)) {
            return (
              <Tooltip title="Transaction Failed." placement="bottom">
                <Typography
                  sx={{
                    color: "#C30010",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(95,0,16,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#C30010" }} />
                  </span>{" "}
                  {rows[dataIndex].status}
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "manual_transfer") {
            return (
              <Tooltip
                title="Transaction was manually sent to recipient."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#0047AB",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0,71,171,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#0047AB" }} />
                  </span>{" "}
                  Manual Transfer
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "manual_cancelled") {
            return (
              <Tooltip
                title="Transaction cancellation has been triggered manually."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#A30000",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(163,0,0,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#A30000" }} />
                  </span>{" "}
                  Manual Cancelled
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "received") {
            return (
              <Tooltip title="C2B callback received." placement="bottom">
                <Typography
                  sx={{
                    color: "#228C22",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(34,140,34,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#228C22" }} />
                  </span>{" "}
                  Received
                </Typography>
              </Tooltip>
            );
          } else if (["stk_push_sent", "at_sent", "b2c_request_sent", "sent_to_waas"].includes(rows[dataIndex].status)) {
            return (
              <Tooltip title="STK push sent to sender." placement="bottom">
                <Typography
                  sx={{
                    color: "#299617",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(41,150,23,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#299617" }} />
                  </span>{" "}
                  {rows[dataIndex].status}
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "reverse") {
            return (
              <Tooltip
                title="Transaction reverse is triggered."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#A53E76",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(165,62,118,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#A53E76" }} />
                  </span>{" "}
                  Reverse Triggered
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "reversed") {
            return (
              <Tooltip
                title="Transaction reversed to sender."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#800020",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(128,0,32,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#800020" }} />
                  </span>{" "}
                  Reversed
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "check_status") {
            return (
              <Tooltip
                title="Checking transaction status from safaricom."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#ff781f",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(255,120,31,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#ff781f" }} />
                  </span>{" "}
                  Checking Status...
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "reversal_requested") {
            return (
              <Tooltip
                title="User requested for a reversal."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#1167b1",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(17,103,177,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#ff781f" }} />
                  </span>{" "}
                  Reversal Requested
                </Typography>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={`${rows[dataIndex].status}`} placement="bottom">
                <Typography
                  sx={{
                    color: "#AA5B00",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(170,91,0,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#AA5B00" }} />
                  </span>{" "}
                  {rows[dataIndex].status}
                </Typography>
              </Tooltip>
            );
          }
        },
      },
    },
    // final status
    {
      name: "finalStatus",
      label: "Final Status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if (["cancelled"].includes(rows[dataIndex].finalStatus)) {
            return (
              <Tooltip
                title="Transaction has failed checks or manually cancelled."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "red",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(255,0,0,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "red" }} />
                  </span>
                  {rows[dataIndex].finalStatus}
                </Typography>
              </Tooltip>
            );
          } else if (["success", "pinless_success", "waas_success", "callback_success", "at_success"].includes(rows[dataIndex].finalStatus)) {
            return (
              <Tooltip
                title=""
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#009966",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0,153,102,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#009966" }} />
                  </span>{" "}
                  {rows[dataIndex].finalStatus}
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "approved") {
            return (
              <Tooltip title="Transaction passed all tests." placement="bottom">
                <Typography
                  sx={{
                    color: "#4B6043",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(75,96,67,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#4B6043" }} />
                  </span>{" "}
                  Approved
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "manual_sent") {
            return (
              <Tooltip
                title="Status has been manually changed."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#03C04A",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(3,192,74,.2)",
                    padding: ".2rem",
                    width: "98%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#03C04A" }} />
                  </span>{" "}
                  Manual Sent
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "sent") {
            return (
              <Tooltip
                title="Money received by recipient. B2C Callback came back."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#3BB143",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(59,177,67,.2)",
                    padding: ".2rem",
                    width: "85%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#3BB143" }} />
                  </span>{" "}
                  Sent
                </Typography>
              </Tooltip>
            );
          } else if (["failed", "waas_failed", "callback_failed", "pinless_failed", "b2c_request_failed", "at_failed"].includes(rows[dataIndex].finalStatus)) {
            return (
              <Tooltip title="Transaction Failed." placement="bottom">
                <Typography
                  sx={{
                    color: "#C30010",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(95,0,16,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#C30010" }} />
                  </span>{" "}
                  {rows[dataIndex].finalStatus}
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "manual_transfer") {
            return (
              <Tooltip
                title="Transaction was manually sent to recipient."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#0047AB",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0,71,171,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#0047AB" }} />
                  </span>{" "}
                  Manual Transfer
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "manual_cancelled") {
            return (
              <Tooltip
                title="Transaction cancellation has been triggered manually."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#A30000",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(163,0,0,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#A30000" }} />
                  </span>{" "}
                  Manual Cancelled
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "received") {
            return (
              <Tooltip title="C2B callback received." placement="bottom">
                <Typography
                  sx={{
                    color: "#228C22",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(34,140,34,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#228C22" }} />
                  </span>{" "}
                  Received
                </Typography>
              </Tooltip>
            );
          } else if (["stk_push_sent", "at_sent", "b2c_request_sent", "sent_to_waas"].includes(rows[dataIndex].finalStatus)) {
            return (
              <Tooltip title="STK push sent to sender." placement="bottom">
                <Typography
                  sx={{
                    color: "#299617",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(41,150,23,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#299617" }} />
                  </span>{" "}
                  STK Sent
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "reverse") {
            return (
              <Tooltip
                title="Transaction reverse is triggered."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#A53E76",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(165,62,118,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#A53E76" }} />
                  </span>{" "}
                  Reverse Triggered
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "reversed") {
            return (
              <Tooltip
                title="Transaction reversed to sender."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#800020",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(128,0,32,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#800020" }} />
                  </span>{" "}
                  Reversed
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "check_status") {
            return (
              <Tooltip
                title="Checking transaction status from safaricom."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#ff781f",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(255,120,31,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#ff781f" }} />
                  </span>{" "}
                  Checking Status...
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].finalStatus === "reversal_requested") {
            return (
              <Tooltip
                title="User requested for a reversal."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#1167b1",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(17,103,177,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#ff781f" }} />
                  </span>{" "}
                  Reversal Requested
                </Typography>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={`${rows[dataIndex].finalStatus}`} placement="bottom">
                <Typography
                  sx={{
                    color: "#AA5B00",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(170,91,0,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#AA5B00" }} />
                  </span>{" "}
                  {rows[dataIndex].finalStatus ?? rows[dataIndex].status}
                </Typography>
              </Tooltip>
            );
          }
        },
      },
    },
    // date created
    {
      name: "createdAt",
      label: "Date Created",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format(dateTimeFormat)},  To: ${moment(
                  v[1]
                ).format(dateTimeFormat)}`
              );

              return [
                `Requested From: ${moment(v[0]).format(dateTimeFormat)}`,
                `Requested To: ${moment(v[1]).format(dateTimeFormat)}`,
              ];
            }
            if (v[0]) {
              return `Requested From: ${moment(v[0]).format(dateTimeFormat)}`;
            }
            if (v[1]) {
              return `Requested To: ${moment(v[1]).format(dateTimeFormat)}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(createdAt, filters) {
            const formattedDate =
              typeof createdAt === "string"
                ? moment(createdAt, "DD-MM-YYYY").format("MM-DD-YYYY")
                : moment(createdAt?.toDate()?.toISOString())?.format(
                  dateTimeFormat2
                );

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format(dateTimeFormat2);
              let formattedFrom = moment(filters[0]).format(dateTimeFormat2);

              const isBetweenFound = (
                moment(formattedDate, dateTimeFormat2).isBetween(
                  formattedFrom,
                  formattedTo,
                  undefined,
                  "[]"
                )
              );

              return !isBetweenFound;
            }

            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format(dateTimeFormat2);
              return moment(formattedDate, dateTimeFormat2).isSameOrAfter(formattedFrom);
            }

            if (filters[0]) {
              let formattedTo = moment(filters[0]).format(dateTimeFormat2);
              return moment(formattedDate, dateTimeFormat2).isSameOrBefore(formattedTo);
            }

            return false;
          },
          display: (filterList, onInputChange, index, column) => {
            return (
              <Grid container direction="row" spacing={2}>
                <Grid item md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Requested From:"
                      value={requestDateFrom || filterList[index][0] || midnightOfToday()}
                      onChange={(newValue) => {
                        setRequestDateFrom(newValue);
                        filterList[index][0] = newValue;
                        onInputChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          sx: { width: '100%' },
                        },
                        layout: {
                          sx: {
                            [`.${pickersLayoutClasses.contentWrapper} > div`]: {
                              flexDirection: "column",
                            },
                            [`.MuiDivider-fullWidth`]: {
                              display: "none",
                            },
                            [`.MuiMultiSectionDigitalClock-root`]: {
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              height: "108px",
                            },
                            [`.MuiMultiSectionDigitalClock-root > ul:nth-child(1)`]: {
                              border: "1px solid rgba(0, 0, 0, 0.375)",
                              borderRight: "none",
                              borderBottomLeftRadius: "8px",
                              borderTopLeftRadius: "8px",
                            },
                            [`.MuiMultiSectionDigitalClock-root > ul:nth-child(2)`]: {
                              border: "1px solid rgba(0, 0, 0, 0.375)",
                              borderLeftColor: "rgba(0, 0, 0, 0.25)",
                              borderLeftStyle: "dashed",
                              borderBottomRightRadius: "8px",
                              borderTopRightRadius: "8px",
                            },
                            [`.MuiMultiSectionDigitalClock-root > ul > li.Mui-selected`]: {
                              borderRadius: "8px",
                            },
                          },
                        },
                      }}
                      ampm={false}
                      timeSteps={{
                        minutes: 1,
                        seconds: 1,
                      }}
                      orientation="portrait"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Requested To:"
                      value={requestDateTo || filterList[index][1] || new Date()}
                      onChange={(newValue) => {
                        if (!(requestDateFrom || filterList[index][0])) {
                          setRequestDateFrom(midnightOfToday());
                          filterList[index][0] = midnightOfToday();
                        }
                        setRequestDateTo(newValue);
                        filterList[index][1] = newValue;
                        onInputChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          sx: { width: '100%' },
                        },
                        layout: {
                          sx: {
                            [`.${pickersLayoutClasses.contentWrapper} > div`]: {
                              flexDirection: "column",
                            },
                            [`.MuiDivider-fullWidth`]: {
                              display: "none",
                            },
                            [`.MuiMultiSectionDigitalClock-root`]: {
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              height: "108px",
                            },
                            [`.MuiMultiSectionDigitalClock-root > ul:nth-child(1)`]: {
                              border: "1px solid rgba(0, 0, 0, 0.375)",
                              borderRight: "none",
                              borderBottomLeftRadius: "8px",
                              borderTopLeftRadius: "8px",
                            },
                            [`.MuiMultiSectionDigitalClock-root > ul:nth-child(2)`]: {
                              border: "1px solid rgba(0, 0, 0, 0.375)",
                              borderLeftColor: "rgba(0, 0, 0, 0.25)",
                              borderLeftStyle: "dashed",
                              borderBottomRightRadius: "8px",
                              borderTopRightRadius: "8px",
                            },
                            [`.MuiMultiSectionDigitalClock-root > ul > li.Mui-selected`]: {
                              borderRadius: "8px",
                            },
                          },
                        },
                      }}
                      ampm={false}
                      timeSteps={{
                        minutes: 1,
                        seconds: 1,
                      }}
                      orientation="portrait"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )
          },
          fullWidth: true,
        },
        customBodyRenderLite: (dataIndex) => {
          return typeof rows[dataIndex].createdAt === "string"
            ? moment(rows[dataIndex].createdAt, "DD-MM-YYYY").format(
              "MMMM Do YYYY, HH:mm:ss"
            )
            : moment(rows[dataIndex].createdAt?.toDate()).format(
              "MMMM Do YYYY, HH:mm:ss"
            );
        },
      },
    },
    // more menu
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex]) {
            return (
              <KcbWalletMoreMenu
                transactionId={rows[dataIndex]["docId"]}
                transactionData={rows[dataIndex]}
              />
            );
          }
        },
      },
    },
  ];

  const handleSearch = async (input) => {
    const senderNoMatches = [];
    const senderCodesMatched = [];
    const recipientNoMatches = [];
    let phoneNumMatches = [];
    const namesMatched = [];

    let numCheck = false;

    const numRegex = /^\d+$/;

    const inputCheck = (string) => {
      if (string.match(numRegex)) {
        numCheck = true;
        return numCheck;
      }

      return numCheck;
    };

    inputCheck(input);

    console.log("NUM CHECK: ", numCheck);

    // check for numbers
    if (numCheck) {
      // get sender number match query
      const searchPhoneNumberQuery = query(
        collection(db, KCB_WALLET_TRANSACTIONS),
        where("phoneNumber", "==", input),
        // orderBy("-createdAt"),
      );

      // get recipient number match query
      // const searchRecipientNoQuery = query(
      //   collection(db, KCB_WALLET_TRANSACTIONS),
      //   where("beneficiary", "==", input)
      // );

      const phoneNumberSnapshot = await getDocs(searchPhoneNumberQuery);
      // const recipientSnapshot = await getDocs(searchRecipientNoQuery);

      phoneNumberSnapshot.forEach((doc) =>
        senderNoMatches.push({ ...doc.data(), docId: doc.id })
      );

      // recipientSnapshot.forEach((doc) =>
      //   recipientNoMatches.push({ ...doc.data(), docId: doc.id })
      // );

      // phoneNumMatches = senderNoMatches.concat(recipientNoMatches);
      phoneNumMatches = [...senderNoMatches];

      setRows(phoneNumMatches);
    }
    // else {
    //   const strCheck = /^[a-zA-Z0-9]+$/;

    //   if (strCheck.test(input) !== true) {
    //     //check for names
    //     const searchNameQuery = query(
    //       collection(db, KCB_WALLET_TRANSACTIONS),
    //       where("displayName", "==", input)
    //     );

    //     const nameSnapshot = await getDocs(searchNameQuery);
    //     nameSnapshot.forEach((doc) =>
    //       namesMatched.push({ ...doc.data(), docId: doc.id })
    //     );

    //     setRows(namesMatched);
    //   } else {
    //     const senderMpesaCodeQuery = query(
    //       collection(db, "mpesa_paybill_stk_session"),
    //       where("MpesaReceiptNumber", "==", input)
    //     );

    //     const senderCodeSnapshot = await getDocs(senderMpesaCodeQuery);
    //     senderCodeSnapshot.forEach(async (doc) => {
    //       senderCodesMatched.push({ ...doc.data(), docId: doc.id });
    //     });

    //     const sendMoneyPromises = senderCodesMatched.map(async (doc) => {
    //       const dataQuery = query(
    //         collection(db, KCB_WALLET_TRANSACTIONS),
    //         where("CheckoutRequestID", "==", doc.CheckoutRequestID)
    //       );

    //       const dataSnapshot = await getDocs(dataQuery);
    //       return dataSnapshot.docs.map((doc) => {
    //         return doc.data();
    //       });
    //     });

    //     const sendMoneyResults = await Promise.all(sendMoneyPromises);
    //     const sendMoneyMatch = sendMoneyResults.flat();

    //     setRows(sendMoneyMatch);
    //   }
    // }
  };

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();

    console.log("Filters: ", filters);

    filters.map((item) => {
      const key = columns[`${filters?.indexOf(item)}`].name;

      // console.log("Key ::", key);

      let newFilter;

      if (key === "createdAt") {
        newFilter = {
          requestDateFrom: (
            item[0]
              ? moment(item[0]).format("DD-MM-YYYY, hh:mm A")
              : moment(midnightOfToday()).format("DD-MM-YYYY, hh:mm A")
          ),
          requestDateTo: moment(item[1]).format("DD-MM-YYYY, hh:mm A"),
        };
      } else {
        // newFilter = { [key]: moment(item[0]).format("DD-MM-YYYY") };
        newFilter = { [key]: item[item[0]] };
      }

      // console.log("New Filter ::", newFilter);

      Object.assign(filterList, newFilter);
    });

    console.log("FILTER LIST: ", filterList);

    setIsFiltered(true);
  };

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);

    if (searchInput.length <= 0) {
      const fetchMoreData = (key) => {
        console.log("LAST KEY: ", key);
        if (key) {
          Pagination.fetchNextBatch(
            key,
            KCB_WALLET_TRANSACTIONS,
            "createdAt"
          )
            .then((res) => {
              setLastKey(res.lastKey);
              setRows(rows.concat(res.fetchedData));
              setIsLoading(false);
            })
            .catch((err) => {
              console.log("Err: ", err);
            });
        }
      };

      fetchMoreData(lastKey);
    }
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };

  // pull data from firebase and set table rows
  useEffect(() => {
    Pagination.fetchFirstBatch(KCB_WALLET_TRANSACTIONS, "createdAt")
      .then((res) => {
        setRows(res.fetchedData);
        setLastKey(res.lastKey);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  // console.log("kcb_wallet_transactions: ", rows[0]);

  return (
    <Box sx={{ bgcolor: "#F7F7F7" }}>
      <KcbWalletToolbar
        setSearchInput={setSearchInput}
        searchRef={searchRef}
        handleSearch={() => handleSearch(searchInput)}
      />

      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MUIDataTable data={rows} columns={columns} options={options} />
          )}
        </ThemeProvider>
      </CacheProvider>
    </Box>
  );
};

export default KcbWalletTransactions;
