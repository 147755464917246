import React from 'react';
import {
  MRT_GlobalFilterTextField as GlobalFilter,
  useMaterialReactTable,
} from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { Box } from '@mui/material';
import MenuButton from '../dropdown/DropDownMenu';
import { ArrowDropDown, TuneOutlined } from '@mui/icons-material';
import { TableTitle } from './CommonTableCell';

const getTableRowStyles = (isOdd) => ({
  ...(isOdd && {
    backgroundColor: '#f0f0f0',
  }),
  '& td, & th': {
    border: 0,
  },
});

const handleSelectAllClick = (tableInstance, setPageRows, canSelectAll) => {
  if (tableInstance.table.getIsAllRowsSelected()) {
    tableInstance.table.setRowSelection({});
  } else {
    const newSelection = {};
    if (canSelectAll) {
      const currentPageRows = tableInstance.table.page.rows.map((row) => row.original);
      setPageRows?.(currentPageRows);
      currentPageRows.forEach((row) => {
        newSelection[row.id] = true;
      });
      tableInstance.table.setRowSelection(newSelection);
    }
  }
};

const CommonTable = ({
  columns,
  data,
  hasSelectAll = false,
  rowSelection,
  rowCheckboxSelectsAll,
  isLoading = false,
  setRowSelection,
  setPageRows,
  searchOptions,
  setSearchParams,
  tableTitle='',
  setPagination,
  pagination,
  totalItems
}) => {


  const table = useMaterialReactTable({
    data: data,
    columns,
    positionGlobalFilter: 'top',
    onRowSelectionChange: setRowSelection,
    state: { rowSelection, isLoading: isLoading, pagination },
    enableTopToolbar: true,
    enableColumnFilterModes: true,
    enableToolbarInternalActions: true,
    enableFilterMatchHighlighting: true,
    enableColumnActions: false,
    enableRowSelection: hasSelectAll,
    paginationDisplayMode: 'pages',
    onPaginationChange: setPagination,
    muiPaginationProps: {
      rowsPerPageOptions: [10,25,50,100],
      showFirstButton: true,
      showLastButton: true,
      count:totalItems,
      page: pagination.page,
      rowsPerPage: pagination.perPage,
      onPageChange: (event, newPage) => {
        setPagination((prev) => ({ ...prev, page: newPage }));
      },
      onRowsPerPageChange: (event) => {
        setPagination((prev) => ({ ...prev, perPage: parseInt(event.target.value, 10) }));
      },
    },
    autoResetAll: false,
    autoResetPageIndex: false,
    enableFilters: true,
    showGlobalFilter: true,
    initialState: { showGlobalFilter: true },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my:'15px',
          mx:'25px'
        }}
      >
        <TableTitle text={tableTitle}/>
        <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my:'15px',
          mx:'25px'
        }}>
        <GlobalFilter table={table} />
        <div style={{marginLeft:'15px'}}></div>
        <MenuButton
          text="Select to filter by"
          size="medium"
          color="primary"
          variant="outlined"
          startIcon={<TuneOutlined style={{ marginRight: '3px' }} />}
          endIcon={<ArrowDropDown style={{ marginLeft: '15px' }} />}
          setSearchParams={setSearchParams}
          nestedOptions={searchOptions}
          onMainSelect={(mainOption, nestedOption) => {
            setSearchParams((prev) => {
              if (prev[mainOption] === nestedOption) return prev;
              return { ...prev, [mainOption]: nestedOption };
            });
          }}
        />
        </Box>
      </Box>
    ),
    muiSelectAllCheckboxProps: (tableInstance) => ({
      onClick: () => handleSelectAllClick(tableInstance, setPageRows, true),
      sx: {
        color: '#009966',
        borderRadius: '4px',
        height: '16px',
        width: '16px',
        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          color: '#009966',
        },
      },
    }),
    muiSelectCheckboxProps: (tableInstance) => ({
      onClick: () =>
        rowCheckboxSelectsAll ? handleSelectAllClick(tableInstance, null, false) : null,
      sx: {
        color: '#49454F',
        borderRadius: '4px',
        height: '16px',
        width: '16px',
        '&.Mui-checked': {
          color: '#009966',
        },
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableProps: {
      sx: {
        border: '1px #f0f0f0 solid',
        borderRadius: '6px',
      },
    },
    muiTableHeadCellProps: {
      align: 'left',
      sx: {
        fontSize: '12px',
        color: '#212427',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        width: 'auto',
      },
    },
    muiTableBodyCellProps: {
      align: 'left',
      color: '#49454F',
      sx: {
        border: '1px gray solid',
      },
    },
    muiTableHeadRowProps: {
      sx: {
        border: 'none',
        boxShadow: 'none',
        
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: getTableRowStyles(row.index % 2 === 1),
    }),
  });

  return (
    <div className="h-full relative">
      <MaterialReactTable table={table} />
    </div>
  );
};

export default CommonTable;
