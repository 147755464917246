import React, { useEffect, useState } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box, Button, Stack } from "@mui/material";

import { Pagination } from "../../../firebase/operations";

import { EnhancedTableToolbar } from "./exciseDutyToolbar";
import CircularStatic from "../../shared/Loader/loader";

import { tableStyles } from "../../styles/tableStyles";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

export default function Excise() {
  const [rows, setRows] = useState([]); // table rows
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [lastUpdatedFrom, setLastUpdatedFrom] = useState(null);
  const [lastUpdatedTo, setLastUpdatedTo] = useState(null);

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    console.log("Filters: ", filters);
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      let newFilter;
      if (key === "lastUpdated") {
        newFilter = {
          lastUpdatedFrom: moment(item[0]).format("DD-MM-YYYY"),
          lastUpdatedTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else {
        newFilter = { [key]: moment(item[0]).format("DD-MM-YYYY") };
      }
      Object.assign(filterList, newFilter);
    });
    console.log("FILTER LIST: ", filterList);
    setIsFiltered(true);
  };

  const columns = [
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
      },
    },
    {
      name: "amountPaid",
      label: "Amount Paid",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) =>
          `KES ${rows[dataIndex].amountPaid}`,
      },
    },
    {
      name: "amountToRepay",
      label: "Amount To Repay",
      options: {
        filter: false,

        customBodyRenderLite: (dataIndex) =>
          `KES ${rows[dataIndex].amountToRepay}`,
      },
    },
    {
      name: "lastUpdated",
      label: "Last Updated",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Last Updated From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Last Updated To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Last Updated From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Last Updated To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(lastUpdated, filters) {
            const formattedDate =
              typeof lastUpdated === "string"
                ? moment(lastUpdated, "DD-MM-YYYY").format("MM-DD-YYYY")
                : moment(lastUpdated.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );

            // console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated From:"
                  value={lastUpdatedFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setLastUpdatedFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated To:"
                  value={lastUpdatedTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setLastUpdatedTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                   
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) => {
          return typeof rows[dataIndex].lastUpdated === "string"
            ? moment(rows[dataIndex].lastUpdated, "DD-MM-YYYY").format(
                "MM-DD-YYYY"
              )
            : moment(rows[dataIndex].lastUpdated.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              );
        },
      },
    },
  ];

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);

    const fetchMoreData = (key) => {
      if (key) {
        Pagination.fetchNextBatch(key, "loans_v2_excise_duty", "lastUpdated")
          .then((res) => {
            setLastKey(res.lastKey);
            setRows(rows.concat(res.fetchedData));
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("Err: ", err);
          });
      }
    };

    fetchMoreData(lastKey);
    console.log("last key:", lastKey);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };

  // pull data from firebase and set table rows
  useEffect(() => {
    Pagination.fetchFirstBatch("loans_v2_excise_duty", "lastUpdated")
      .then((res) => {
        setRows(res.fetchedData);
        setLastKey(res.lastKey);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  console.log("excise", rows);

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <EnhancedTableToolbar />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MUIDataTable data={rows} columns={columns} options={options} />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
