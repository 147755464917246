import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";

export const EnhancedTransactionToolbar = (props) => {
  const { numSelected } = props;

  const handleChange = () => {
    props.setSearchInput(props.searchRef.current?.value);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        backgroundColor: "#f2fffb",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "700",
            fontSize: "26px",
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Transactions
        </Typography>
        <TextField
          placeholder="Search by transaction ID, account, etc."
          id="outlined-start-adornment"
          sx={{ m: 1, width: "40%", backgroundColor: "white" }}
          inputRef={props.searchRef}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  onClick={props.handleSearch}
                  sx={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Toolbar>
  );
};
