import React, { useState, useEffect, forwardRef } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import FormControl from "@mui/material/FormControl";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MuiAlert from "@mui/material/Alert";
import { KYCOperations } from "../../firebase/operations";
import { Grid } from "@mui/material";

const InputFeedback = ({ error }) =>
  error ? (
    <Typography
      color="error"
      sx={{
        fontSize: "0.8rem",
        fontFamily: "Rubik, sans-serif",
        marginLeft: "1rem",
        marginTop: "0.3rem",
      }}
    >
      {error}
    </Typography>
  ) : null;

export default function CreateBusiness({ userId, userData }) {
  const [open, setOpen] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [sessionUser, setSessionUser] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setSessionUser(user);
  }, []);

  const CreateBusinessSchema = Yup.object().shape({
    paymentOption: Yup.string().required("Payment Option is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    businessName: Yup.string().required("Business Name is required"),
    businessActivationCode: Yup.string().required("Business Activation Code is required"),
    businessID: Yup.string().required("Business ID is required"),
    businessType: Yup.string().required("Business Type is required"),
    payBillNo: Yup.string().when("paymentOption", {
      is: "paybill",
      then: Yup.string().required("PayBill Number is required"),
      otherwise: Yup.string(),
    }),
    accountNo: Yup.string().when("paymentOption", {
      is: "paybill",
      then: Yup.string().required("Account Number is required"),
      otherwise: Yup.string(),
    }),
    tillNo: Yup.string().when("paymentOption", {
      is: "till",
      then: Yup.string().required("Till Number is required"),
      otherwise: Yup.string(),
    }),
    distributorTo: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      paymentOption: "",
      phoneNumber: userData?.phoneNumber,
      location: "",
      businessName: "",
      businessActivationCode: "",
      businessID: "",
      businessType: "",
      payBillNo: "",
      accountNo: "",
      tillNo: "",
      distributorTo: "",
    },
    validationSchema: CreateBusinessSchema,
    onSubmit: async (values, { resetForm }) => {
      values["isPinVerified"] = false;
      values["status"] = "level_0";
      values["userId"] = userId;
      values["businessVerificationStatus"] = "pending";
      values["createdAt"] = serverTimestamp();
      values["updatedAt"] = serverTimestamp();
      values["onBoardedBy"] = sessionUser?.user?.phoneNumber;
      console.log("Business data: ", values);

      const response = await KYCOperations.onBoardBusiness(values);

      if (response.created) {
        setIsCreated(true);
        setOpenSnackbar(true);
        resetForm();
        handleClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setErrorMsg(response.error);
        setOpenSnackbar(true);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div style={{ width: "20%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isCreated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isCreated
            ? "Business Created successfully!!!"
            : "Cannot Create Business: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <AddIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Onboard Business"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false" maxWidth="md">
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Create Business
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item sm={6} sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    autoComplete="phoneNumber"
                    type="text"
                    margin="normal"
                    label="Merchant Phone Number"
                    {...getFieldProps("phoneNumber")}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Grid>
                <Grid item sm={6} sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    autoComplete="businessName"
                    type="text"
                    margin="normal"
                    label="Business Name"
                    {...getFieldProps("businessName")}
                    error={Boolean(touched.businessName && errors.businessName)}
                    helperText={touched.businessName && errors.businessName}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={6} sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    autoComplete="businessActivationCode"
                    type="text"
                    margin="normal"
                    label="Business Activation Code"
                    {...getFieldProps("businessActivationCode")}
                    error={Boolean(touched.businessActivationCode && errors.businessActivationCode)}
                    helperText={touched.businessActivationCode && errors.businessActivationCode}
                  />
                </Grid>
                <Grid item sm={6} sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    autoComplete="businessID"
                    type="text"
                    margin="normal"
                    label="Business ID"
                    {...getFieldProps("businessID")}
                    error={Boolean(touched.businessID && errors.businessID)}
                    helperText={touched.businessID && errors.businessID}
                  />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                autoComplete="businessType"
                type="text"
                margin="normal"
                label="Business Type"
                {...getFieldProps("businessType")}
                error={Boolean(touched.businessType && errors.businessType)}
                helperText={touched.businessType && errors.businessType}
              />
              <TextField
                fullWidth
                autoComplete="location"
                type="text"
                margin="normal"
                label="Business Location"
                {...getFieldProps("location")}
                error={Boolean(touched.location && errors.location)}
                helperText={touched.location && errors.location}
              />
              <FormControl
                variant="outlined"
                sx={{ width: "100%", mt: "1rem", mb: "0.5rem" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Payment Options
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps("paymentOption")}
                  name="paymentOption"
                  label="Payment Options"
                  error={Boolean(touched.paymentOption && errors.paymentOption)}
                  helperText={touched.paymentOption && errors.paymentOption}
                >
                  <MenuItem value="merchant_wallet">Merchant Wallet</MenuItem>
                  <MenuItem value="paybill">PayBill</MenuItem>
                  <MenuItem value="till">Till</MenuItem>
                </Select>
              </FormControl>
              {touched.paymentOption && (
                <InputFeedback error={errors.paymentOption} />
              )}
              <Grid container spacing={3}>
                <Grid item sm={6} sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    autoComplete="payBillNo"
                    type="text"
                    margin="normal"
                    label="PayBill Number"
                    {...getFieldProps("payBillNo")}
                    error={Boolean(touched.payBillNo && errors.payBillNo)}
                    helperText={touched.payBillNo && errors.payBillNo}
                  />
                </Grid>
                <Grid item sm={6} sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    autoComplete="accountNo"
                    type="text"
                    margin="normal"
                    label="Account Number"
                    {...getFieldProps("accountNo")}
                    error={Boolean(touched.accountNo && errors.accountNo)}
                    helperText={touched.accountNo && errors.accountNo}
                  />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                autoComplete="tillNo"
                type="text"
                margin="normal"
                label="Till Number"
                {...getFieldProps("tillNo")}
                error={Boolean(touched.tillNo && errors.tillNo)}
                helperText={touched.tillNo && errors.tillNo}
              />
              <TextField
                fullWidth
                autoComplete="distributorTo"
                type="text"
                margin="normal"
                label="Distributor To"
                {...getFieldProps("distributorTo")}
                error={Boolean(touched.distributorTo && errors.distributorTo)}
                helperText={touched.distributorTo && errors.distributorTo}
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                  marginRight: "3rem",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
