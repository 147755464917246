import React from 'react'
import LoanTable from './LoanTable'
import { COLORS } from '../../constants'
import CustomTab from '../../sharedComponents/tabs/CustomTab'
import Active from './Active/active'
import Cancelled from './Cancelled/cancelled'
import Disbursed from './Disbursed/disbursed'
import FortuneLoans from './Fortune-Loans/fortune-loans'
import NoFollowUps from './No-Follow-Ups/noFollowUps'
import Overdue from './Overdue/overdue'
import Repaid from './Repaid/repaid'
import Requested from './Requested/requested'
import WrittenOff from './WrittenOff/writtenOff'

export default function LoanTabLayout() {

    const tabs = [
        { label: 'All Loans', component: LoanTable },
        { label: 'Active', component: Active },
        { label: 'Cancelled', component: Cancelled },
        { label: 'Disbursed', component: Disbursed },
        { label: 'Fortune Loans', component: FortuneLoans },
        { label: 'No Follow Ups', component: NoFollowUps },
        { label: 'Overdue', component: Overdue },
        { label: 'Repaid', component: Repaid },
        { label: 'Requested', component: Requested },
        { label: 'Written Off', component: WrittenOff },
      ]
  return (
    <div style={{backgroundColor:COLORS?.backgroundColor}}>
        <CustomTab tabs={tabs} />
    </div>
  )
}
