import Cookies from "js-cookie";
export const ACCESS_TOKEN = "token";
export const ACTIVE_STOREID = "";

export const setCookie = (cookieName, value) => {
  if (cookieName === ACCESS_TOKEN) {
    Cookies.set(cookieName, value, { expires: 1 });
  }
  Cookies.set(cookieName, value);
};

export const getCookie = (cookieName) => {
  const cookie = Cookies.get(cookieName);
  return cookie;
};

export const removeCookie = (cookieName) => {
  Cookies.remove(cookieName);
};
