import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
// import CustomAvatar from "../shared/avatar/CustomAvatar";
import { BUTTON_VARIANTS, COLORS } from "../../constants";
import { formatDate } from "../../utils/formatDate";
import StatusChip from "../../sharedComponents/tables/Chips";
import { getColor } from "../../utils/getColor";
import { calculatePercentage } from "../../utils/calculatePercentage";
import CustomButton from "../../sharedComponents/Buttons/CustomButton";
import { Check, ChevronLeft, Close, Widgets } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetLoansByParams, useGetOneLoan } from "../../awsServices/loans";
import { useFetchToken } from "../../awsServices/token";
import { useQueryClient } from "@tanstack/react-query";
import useToken from "../../hooks/useToken";

const LoanDetails = () => {
  const { id } = useParams();
  const fetchTokenMutation = useFetchToken();
  const queryClient = useQueryClient();
  const { setToken, token } = useToken();
  const getLoansByParamsQuery = useGetOneLoan(token, id);
  const loanDetails= getLoansByParamsQuery?.data?.data?.data;
  const { user } = loanDetails??{};
  const navigate = useNavigate();
console?.log('loanDetails',loanDetails)
  useEffect(() => {
    fetchTokenMutation.mutate(
      {},
      {
        onSuccess: ({ data }) => {
          setToken(data?.access_token);
        },
        onError: (error) => {
          throw new Error(error);
        },
      }
    );
  }, [!token]);
  useEffect(() => {
    queryClient.refetchQueries({ queryKey: ["all loans", { id: id }] });
  }, [id, token, queryClient]);

  const DetailBox = ({ title, label, color, isCenter }) => {
    return (
      <Box
        style={{
          color: COLORS.gray,
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: isCenter ? "center" : "",
        }}
      >
        <Typography
          style={{
            color: color ?? COLORS.gray,
            fontSize: "12px",
            marginBottom: "2px",
            textAlign: isCenter ? "center" : "left",
          }}
        >
          {title}
        </Typography>
        <Typography style={{ fontWeight: "bold", color: COLORS.darkgreen }}>
          {label}
        </Typography>
      </Box>
    );
  };

  const ProgressBarWithLabel = ({ value }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            sx={{
              "& .MuiLinearProgress-bar": {
                backgroundColor: COLORS.darkgreen,
              },
              backgroundColor: COLORS.lightGreen,
            }}
            variant="determinate"
            value={value}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box style={{ backgroundColor: "#f2fffb", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ alignSelf: "flex-start" }}>
          <CustomButton
            onClick={() => navigate(-1)}
            color={COLORS.darkgreen}
            variant={BUTTON_VARIANTS.outlined}
            text="Go Back"
            startIcon={<ChevronLeft />}
          />
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            background: "transparent",
            paddingTop: "16px",
            borderRadius: "12px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Grid
            style={{
              backgroundColor: "white",
              paddingTop: "16px",
              paddingBottom: "16px",
              borderRadius: "12px",
              paddingLeft: "20px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "28px",
                }}
              >
                {" "}
                Personal Information
              </Typography>
            </div>
            <DetailBox
              title={"Full Name"}
              label={`${user?.firstName} ${user?.lastName}`}
            />
            <Divider
              style={{
                color: COLORS.lightGray,
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />

            <DetailBox title={"Phone Number"} label={user?.phoneNumber} />
            <Divider
              style={{
                color: COLORS.lightGray,
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />
            <DetailBox title={"Id Number"} label={user?.idNumber} />
            <Divider
              style={{
                color: COLORS.lightGray,
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />
            <DetailBox
              title={"Loan Limit"}
              label={user?.loanQualification?.loanLimit}
            />
            <Divider
              style={{
                color: COLORS.lightGray,
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />
            <DetailBox
              title={"Date Registered"}
              label={formatDate(loanDetails?.user?.createdAt??'')}
            />
            <Divider
              style={{
                color: COLORS.lightGray,
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />
            <DetailBox
              title={"Last Transunion Check"}
              label={formatDate(loanDetails?.user?.loanQualification?.lastTransunionCheck??'')}
            />
            <Divider
              style={{
                color: COLORS.lightGray,
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{
            background: "transparent",
            paddingTop: "16px",
            borderRadius: "12px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Grid
            style={{
              backgroundColor: "white",
              paddingTop: "16px",
              paddingBottom: "16px",
              borderRadius: "12px",
              paddingLeft: "20px",
              marginBottom: "20px",
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
                paddingTop: "16px",
                borderRadius: "12px",
                paddingLeft: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "28px",
                  }}
                >
                  {" "}
                  Loan Qualification
                </Typography>
              </div>
              <DetailBox
                title={"Does id match?"}
                label={
                  user?.loanQualification?.idMatch ? (
                    <Check sx={{ color: COLORS.darkgreen, height: "20px" }} />
                  ) : (
                    <Close sx={{ color: COLORS.red, height: "20px" }} />
                  )
                }
              />
              <Divider
                style={{
                  color: COLORS.lightGray,
                  width: "100%",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
              <DetailBox
                title={"Does name match?"}
                label={
                  user?.loanQualification?.nameMatch ? (
                    <Check sx={{ color: COLORS.darkgreen, height: "20px" }} />
                  ) : (
                    <Close sx={{ color: COLORS.red, height: "20px" }} />
                  )
                }
              />
              <Divider
                style={{
                  color: COLORS.lightGray,
                  width: "100%",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
              <DetailBox
                title={"Is phone number listed?"}
                label={
                  user?.loanQualification?.phoneNumberListed ? (
                    <Check sx={{ color: COLORS.darkgreen, height: "20px" }} />
                  ) : (
                    <Close sx={{ color: COLORS.red, height: "20px" }} />
                  )
                }
              />
              <Divider
                style={{
                  color: COLORS.lightGray,
                  width: "100%",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
              <DetailBox
                title={"Does user meet loan limit?"}
                label={
                  user?.loanQualification?.meetsLoanLimit ? (
                    <Check sx={{ color: COLORS.darkgreen, height: "20px" }} />
                  ) : (
                    <Close sx={{ color: COLORS.red, height: "20px" }} />
                  )
                }
              />
              <Divider
                style={{
                  color: COLORS.lightGray,
                  width: "100%",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
              <DetailBox
                title={"Has the user received loan in 3 months?"}
                label={
                  user?.loanQualification?.receivedLoanWithinLast3Months ? (
                    <Check sx={{ color: COLORS.darkgreen, height: "20px" }} />
                  ) : (
                    <Close sx={{ color: COLORS.red, height: "20px" }} />
                  )
                }
              />
              <Divider
                style={{
                  color: COLORS.lightGray,
                  width: "100%",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            style={{
              backgroundColor: "white",
              paddingTop: "16px",
              borderRadius: "12px",
              paddingLeft: "20px",
              Widgets: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "20px 0" }}>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "28px",
                    marginBottom: "20px",
                  }}
                >
                  Loan Details
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                padding: "20px",
              }}
            >
              <Typography
                style={{
                  color: COLORS.darkgreen,
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                {loanDetails?.loanAccount?.accountNumber}
              </Typography>
              <div style={{ marginBottom: "10px", width: "100%" }}>
                <DetailBox
                  title="Loan Payment Progress"
                  label=""
                  isCenter={true}
                />
                <ProgressBarWithLabel
                  value={calculatePercentage(
                    loanDetails?.requestedAmount -
                      loanDetails?.loanAccount?.balance,
                    loanDetails?.requestedAmount
                  )}
                />
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Borrowed Amount"}
                  label={loanDetails?.requestedAmount}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Interest Rate"}
                  label={loanDetails?.interestRate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Interest Amount"}
                  label={loanDetails?.interestAmount}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox title={"CRB Fee"} label={loanDetails?.crbFee} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"B2C Charge"}
                  label={loanDetails?.b2cCharge}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Disbursed Amount"}
                  label={loanDetails?.disbursedAmount}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Disbursed Date"}
                  label={formatDate(loanDetails?.dateDisbursed??'')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Repayment Amount"}
                  label={loanDetails?.repaymentAmount}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Repayment Date"}
                  label={formatDate(loanDetails?.repaymentDate??'')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Account Number"}
                  label={loanDetails?.loanAccount?.accountNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DetailBox
                  title={"Loan Balance"}
                  label={loanDetails?.loanAccount?.balance}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  style={{
                    color: COLORS.gray,
                    fontSize: "12px",
                    marginBottom: "2px",
                  }}
                >
                  {"Account Status"}
                </Typography>
                <StatusChip
                  color={
                    getColor(loanDetails?.loanAccount?.accountStatus)?.textColor
                  }
                  bgColor={
                    getColor(loanDetails?.loanAccount?.accountStatus)
                      ?.backgroundColor
                  }
                  value={loanDetails?.loanAccount?.accountStatus}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  style={{
                    color: COLORS.gray,
                    fontSize: "12px",
                    marginBottom: "2px",
                  }}
                >
                  {"Existing Loan Status"}
                </Typography>
                <StatusChip
                  color={getColor(loanDetails?.loanStatus)?.textColor}
                  bgColor={getColor(loanDetails?.loanStatus)?.backgroundColor}
                  value={loanDetails?.loanStatus}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoanDetails;
