import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";

import {Box, Button} from "@mui/material";

import {EnhancedTableToolbar} from "./usersToolbar";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import CircularStatic from "../../shared/Loader/loader";

import {tableStyles} from "../../styles/tableStyles";

// Removed Firebase imports
import {getWalletUsers} from "../../../apis/waasApi"; // WAAS API call

const muiCache = createCache({
    key: "mui-datatables",
    prepend: "true",
});

// table stylings
const getMuiTheme = () => createTheme(tableStyles);

export default function KcbWalletUsers() {
    const [rows, setRows] = useState([]); // table rows
    const [total, setTotal] = useState(0); // page data
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [isFiltered, setIsFiltered] = useState(false);
    const [registerDateFrom, setRegisterDateFrom] = useState(null);
    const [registerDateTo, setRegisterDateTo] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const searchRef = useRef(null);

    const handleSearch = async (input) => {
        setIsLoading(true);
        try {
            const allUsers = await getWalletUsers(
                0,
                rowsPerPage,
                input
            ); // Fetch all users
            console.log("All users:", allUsers);

            setRows(allUsers?.values);
            setTotal(allUsers?.total || 0);
        } catch (error) {
            console.error('Error searching users:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleFilterSubmit = (applyFilters) => {
        const filters = applyFilters();
        setIsLoading(true);

        getWalletUsers().then(allUsers => {
            let filteredUsers = allUsers;

            filters.forEach(filter => {
                const key = columns[filters.indexOf(filter)].name;
                let filterValue = filter[0]; // Assuming single value filters for simplicity

                // Adjust this switch statement based on your actual filter keys
                switch (key) {
                    case "customerDetails.name":
                        filteredUsers = filteredUsers.filter(user =>
                            user.customerDetails.name.toLowerCase().includes(filterValue.toLowerCase())
                        );
                        break;
                    case "customerDetails.MSISDN":
                        filteredUsers = filteredUsers.filter(user =>
                            user.customerDetails.MSISDN.includes(filterValue)
                        );
                        break;
                    case "customerDetails.gender":
                        filteredUsers = filteredUsers.filter(user =>
                            user.customerDetails.gender === filterValue
                        );
                        break;
                    // Add more cases as necessary for other fields in customerDetails
                    default:
                        break;
                }
            });

            setRows(filteredUsers);
            setIsLoading(false);
        }).catch(error => {
            console.error('Error applying filters:', error);
            setIsLoading(false);
        });
    };


    const columns = [
        {
            name: "nationalId",
            label: "ID Number",
            options: {
                filter: false,
            },
        },
        {
            name: "name",
            label: "Full Name",
            options: {
                filter: true,
            },
        },
        {
            name: "msisdn",
            label: "Phone Number",
            options: {
                filter: false,
            },
        },
        {
            name: "gender",
            label: "Gender",
            options: {
                filter: true,
            },
        },
        {
            name: "dateOfBirth",
            label: "Date of Birth",
            options: {
                filter: true,
                customBodyRenderLite: (dataIndex) => {
                    const row = rows[dataIndex];
                    if (row && row.dateOfBirth) {
                        // Ensure the dateOfBirth is in the expected format
                        const formattedDate = moment(row.dateOfBirth, "YYYYMMDD").format("MMMM Do, YYYY");
                        return formattedDate;
                    }
                    return ""; // Return empty string or some default value if dateOfBirth is not available
                },
            },
        },
        {
            name: "kycComplete",
            label: "KYC Complete",
            options: {
                filter: false,

                customBodyRenderLite: (dataIndex) =>
                    rows[dataIndex].docData?.KCBKYCComplete ? (
                        <CheckIcon
                            sx={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "#009966",
                            }}
                        />
                    ) : (
                        <ClearIcon
                            sx={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "red",
                            }}
                        />
                    ),
            },
        },
        {
            name: "iprsStatus",
            label: "IPRS Status",
            options: {
                filter: false,

                customBodyRenderLite: (dataIndex) => {
                    const row = rows[dataIndex];
                    if (row && row.docData) {
                        return row.docData.iprsStatus || "n/a";
                    }
                    return "";
                }
            },
        },
        {
            name: "walletActivated",
            label: "KCB Wallet Activated",
            options: {
                filter: false,

                customBodyRenderLite: (dataIndex) =>
                    rows[dataIndex].docData?.KCBWalletActivated ? (
                        <CheckIcon
                            sx={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "#009966",
                            }}
                        />
                    ) : (
                        <ClearIcon
                            sx={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "red",
                            }}
                        />
                    ),
            },
        },
        {
            name: "hasWalletAccount",
            label: "Has Wallet Account",
            options: {
                filter: false,

                customBodyRenderLite: (dataIndex) =>
                    rows[dataIndex].docData?.KCBWallet ? (
                        <CheckIcon
                            sx={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "#009966",
                            }}
                        />
                    ) : (
                        <ClearIcon
                            sx={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                color: "red",
                            }}
                        />
                    ),
            },
        },
        {
            name: "createdAt",
            label: "Created At",
            options: {
                filter: false,

                customBodyRenderLite: (dataIndex) => {
                    const row = rows[dataIndex];
                    if (row && row.createdAt) {
                        return moment(row.createdAt).format("Do MMMM YYYY, h:mm:ss a");
                    }
                    return "";
                }
            },
        },
        // Add additional columns as needed
    ];


    const handleChangePage = async (page) => {
        setIsLoading(true);
        try {
            // Assuming getWalletUsers API accepts page and limit as parameters
            const fetchedData = await getWalletUsers(page, rowsPerPage);
            setRows(fetchedData.values); // Update based on your API response structure
            setPage(page);
        } catch (error) {
            console.error("Error while fetching page data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeRowsPerPage = async (newRowsPerPage) => {
        setIsLoading(true);
        try {
            const fetchedData = await getWalletUsers(0, newRowsPerPage); // Fetch first page with new limit
            setRows(fetchedData.values); // Update based on your API response structure
            setRowsPerPage(newRowsPerPage);
            setPage(0);
        } catch (error) {
            console.error("Error while changing rows per page:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const options = {
        responsive: "standard",
        rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        download: true,
        print: false,
        confirmFilters: true,
        serverSide: true,
        count: total,
        filter: false,
        onTableChange: (action, tableState) => {
            switch (action) {
                case "changePage":
                    handleChangePage(tableState.page);
                    break;
                case "changeRowsPerPage":
                    handleChangeRowsPerPage(tableState.rowsPerPage);
                    break;
                default:
                    console.log("action not handled.");
            }
        },
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
            <Box style={{marginTop: "40px"}}>
                <Button
                    variant="contained"
                    sx={{
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "500",
                        fontSize: ".88rem",
                        backgroundColor: "#009966",
                        "&:hover": {
                            backgroundColor: "rgba(0,153,102,.8)",
                        },
                    }}
                    onClick={() => handleFilterSubmit(applyNewFilters)}
                >
                    Apply Filters
                </Button>
            </Box>
        ),
    };

    // pull data from the WAAS API and set table rows
    useEffect(() => {
        const fetchUsers = async () => {
            setIsLoading(true);
            try {
                // Fetch the first page of data when the component mounts
                const fetchedData = await getWalletUsers(0, rowsPerPage); // Assuming page 0 and rowsPerPage as initial parameters

                console.log("Fetched data:", fetchedData);
                setRows(fetchedData.values);
                setTotal(fetchedData.total || 0);
                setIsLoading(false);
            } catch (err) {
                console.log("Error while fetching users", err);
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div style={{backgroundColor: "#f2fffb"}}>
            <EnhancedTableToolbar
                setSearchInput={setSearchInput}
                searchRef={searchRef}
                handleSearch={() => handleSearch(searchInput)}
            />
            <CacheProvider value={muiCache}>
                <ThemeProvider theme={getMuiTheme()}>
                    {isLoading ? (
                        <CircularStatic/>
                    ) : (
                        <MUIDataTable data={rows} columns={columns} options={options}/>
                    )}
                </ThemeProvider>
            </CacheProvider>
        </div>
    );
}
