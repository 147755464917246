import axios from 'axios';
import {waasConfig} from './waas-config';
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase/firebase";

let cachedToken = null;
let tokenExpiry = null;

export const getAuthToken = async () => {
    const username = 'lejaV2';
    const password = 'lejaV2';
    const payload = new URLSearchParams();
    payload.append('username', username);
    payload.append('password', password);

    try {
        const response = await axios.post(`${waasConfig.authBaseUrl}/auth/token/`, payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response.data && response.data.access_token) {
            cachedToken = response.data.access_token;
            // Set token expiry time to 60 minutes from now
            tokenExpiry = new Date(new Date().getTime() + 60 * 60000);
            return cachedToken;
        } else {
            throw new Error('Token not received');
        }
    } catch (error) {
        console.error('Error obtaining auth token:', error);
        throw error;
    }
};

const fetchAuthToken = async () => {
    const now = new Date();
    if (cachedToken && tokenExpiry && now < tokenExpiry) {
        return cachedToken;
    }
    return getAuthToken();
};

export const getWalletUsers = async (page = 0, limit = 10, search="") => {
    try {
        const authToken = await fetchAuthToken();
        const searchParams = search ? {search} : {};
        const response = await axios.get(`${waasConfig.baseUrl}/wallet/accounts`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
            params: {limit, skip: page * limit, ...searchParams}
        });

        const usersWithDocData = await Promise.all(response.data.values.map(async (user) => {
            const getUserQuery = query(
                collection(db, "users"),
                where("idNumber", "==", user.nationalId),
                where("phoneNumber", "==", user.msisdn),
            );

            const userQuerySnapshot = await getDocs(getUserQuery);
            const userData = userQuerySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    docId: doc.id,
                    KCBKYCComplete: data.KCBKYCComplete,
                    KCBWallet: data.KCBWallet,
                    KCBWalletActivated: data.KCBWalletActivated,
                    iprsStatus: data.iprsStatus,
                    iprsFailureNote: data.iprsFailureNote,
                }
            });
            if (userData.length > 0) {
                return {...user, docData: userData[0]};
            }

            return {...user, docData: {}};
        }));

        return {...response.data, values: usersWithDocData};
    } catch (error) {
        console.error('Error fetching wallet users:', error);
        throw error;
    }
};

export const getWalletTransactions = async (page = 0, limit = 10) => {
    try {
        const authToken = await fetchAuthToken();
        const response = await axios.get(`${waasConfig.baseUrl}/wallet/transactions`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
            params: {limit, skip: page * limit}
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching wallet transactions:', error);
        throw error;
    }
};
