
import { requests } from "../helper/apiHelper";
import { LOANS } from "./endpoints";
import { useQuery } from '@tanstack/react-query'

export async function getLoans(token) {
   
    const response = await requests.get(LOANS.allLoans,token);
    return response;
}

export async function getOneLoan(token,id) {
   
  const response = await requests.get(LOANS.getOneLoan(id),token);
  return response;
}
export async function getLoansByParams(token, params={}) {
  const response = await requests.getWithParams(LOANS.allLoans, token, params);
  return response;
}


export const useGetAllLoans = (token) => {
    return useQuery({
      queryKey: ["all loans"],
      enabled:!!token,
      queryFn: () => getLoans(token)
    });
  };
  export const useGetOneLoan = (token,id) => {
    return useQuery({
      queryKey: ["all loans"],
      enabled:!!token,
      queryFn: () => getOneLoan(token,id)
    });
  };
  export const useGetLoansByParams = (token, config = {}) => {
    return useQuery({
      queryKey: ["all loans", config],
      enabled: !!token,
      queryFn: () => getLoansByParams(token, config),
    });
  };
