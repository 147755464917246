export const KcbWalletTxnStatus = {
  initiated: 'initiated', // transaction initiated
	success: 'success', // transaction successful
	sent_to_waas: 'sent_to_waas', // request sent to waas
	waas_failed: 'waas_failed', // waas request failed
	waas_success: 'waas_success', // waas request successful
	stk_push_sent: 'stk_push_sent', // stk push sent to daraja
	b2c_request_sent: 'b2c_request_sent', // b2c request sent to daraja
	b2c_request_failed: 'b2c_request_failed', // b2c request failed
	callback_failed: 'callback_failed', // daraja callback failed
	callback_success: 'callback_success', // daraja callback success
	failed: 'failed', // transaction failed
	at_sent: 'at_sent', // airtime request sent to africastalking
	at_failed: 'at_failed', // airtime request failed
	at_success: 'at_success', // airtime request successful
	pinless_success: 'pinless_success', // pinless request successful
	pinless_failed: 'pinless_failed', // pinless request failed
	pending: 'pending', // For any transaction not in the final state
	
	manual_transfer: 'manual_transfer', // For any transaction that has been triggered manually
};
