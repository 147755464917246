import React, { useEffect, useMemo, useState } from 'react';
import CommonTableCell from '../../sharedComponents/tables/CommonTableCell';
import StatusChip from '../../sharedComponents/tables/Chips';
import CommonTable from '../../sharedComponents/tables/CommonTable';
import { getColor } from '../../utils/getColor';
import { useFetchToken } from '../../awsServices/token';
import useToken from '../../hooks/useToken';
import { useGetLoansByParams } from '../../awsServices/loans';
import { useQueryClient } from '@tanstack/react-query';
import { formatDate, formatDateTime } from '../../utils/formatDate';
import { COLORS, STATUS } from '../../constants';
import CustomButton from '../../sharedComponents/Buttons/CustomButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useNavigate } from 'react-router-dom';


export default function LoanTable() {
  const [rowSelection, setRowSelection] = useState({});
  const [params,setParams]=useState({})
  const navigate=useNavigate()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const fetchTokenMutation = useFetchToken();
  const queryClient = useQueryClient();
  const { setToken, token } = useToken();
  const getLoansByParamsQuery=useGetLoansByParams(token,{...params})
  const loans = getLoansByParamsQuery?.data?.data?.data;
  const searchParamsOptions = [
    {
      main: 'loan',
      nested: [
        STATUS.Active,
        STATUS.Requested,
        STATUS.Rejected,
        STATUS.PendingDisbursement,
        STATUS.PartiallyPaid,
        STATUS.Defaulted,
        STATUS.Cancelled,
        STATUS.Completed
      ]
    },
    {
      main: 'account',
      nested: [
        STATUS.Active,
        STATUS.Inactive,
        STATUS.ApplicationFailed,
        STATUS.Closed
      ]
    }

  ];


  useEffect(() => {
    fetchTokenMutation.mutate(
      {},
      {
        onSuccess: ({ data }) => {
        
          setToken(data?.access_token); 
        },
        onError: (error) => {
          throw new Error(error)
        },
      }
    );
  }, [!token]);



  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: '#',
        size: 50,
        enableSorting: false,
        Cell: ({ row }) => (
          <div>
            <CommonTableCell tableContent={(row?.index + 1).toString()} />
          </div>
        ),
      },
      {
        accessorFn: (row) => row?.user?.firstName,
        header: 'Full Name',
        size: 100,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: ({ row }) => {
          const fullname = `${row?.original?.user?.firstName} ${row?.original?.user?.lastName}`;
          return (
            <div>
              <CommonTableCell tableContent={fullname?.toString() ?? '-'} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.loanAccount?.accountNumber,
        header: ' Account Number',
        size: 100,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: (row) => {
          const accountNumber = `${row?.row?.original?.loanAccount?.accountNumber}`;
          return (
            <div>
              <CommonTableCell tableContent={accountNumber?.toString() ?? '-'} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.requestedAmount,
        header: 'Principal Amount',
        size: 50,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: ({ row }) => {
          const amountRequested = row?.original?.requestedAmount ?? '-';
          return (
            <div>
              <CommonTableCell tableContent={amountRequested} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.disbursedAmount,
        header: 'Amount Disbursed',
        size: 50,
        enableSorting: true,
        enableGlobalFilter: true,
        Cell: ({ row }) => {
          const amountDisbursed = row?.original?.disbursedAmount ?? '-';
          return (
            <div>
              <CommonTableCell tableContent={amountDisbursed} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.repaymentAmount,
        header: 'Amount Repaid',
        size: 50,
        enableSorting: true,
        Cell: ({ row }) => {
          const amountRepaid = row?.original?.repaymentAmount - row?.original?.loanAccount?.balance;
          return (
            <div>
              <CommonTableCell tableContent={amountRepaid} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.interestRate,
        header: 'Interest Rate',
        size: 50,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: (row) => (
          <div>
            <CommonTableCell tableContent={row?.row?.original?.interestRate?.toString() ?? '-'} />
          </div>
        ),
      },
      {
        accessorFn: (row) => row?.repaymentDate,
        header: 'Repayment Date',
        size: 50,
        enableSorting: true,
        Cell: ({ row }) => {
          const repaymentDate = row?.original?.repaymentDate ?? '';
          return (
            <div>
              <CommonTableCell tableContent={repaymentDate.length > 0 ? formatDate(repaymentDate) : '-'} tableSubContent={repaymentDate.length > 0 ? formatDateTime(repaymentDate) : ''} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.createdAt,
        header: 'Date Requested',
        size: 50,
        enableSorting: true,
        sortDescFirst:true,
        Cell: ({ row }) => {
          const requestedDate = row?.original?.createdAt ?? '';
          return (
            <div>
              <CommonTableCell tableContent={requestedDate.length > 0 ? formatDate(requestedDate) : '-'} tableSubContent={requestedDate.length > 0 ? formatDateTime(requestedDate) : ''} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.loanAccount?.accountStatus,
        header: 'Account Status',
        size: 50,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: (row) => {
          const status = row?.renderedCellValue;
          return (
            <div>
              <StatusChip value={status} bgColor={getColor(status)?.backgroundColor} color={getColor(status)?.textColor} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.loanStatus,
        header: 'Loan Status',
        size: 50,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: (row) => {
          const status = row?.renderedCellValue;
          return (
            <div>
              <StatusChip value={status} bgColor={getColor(status)?.backgroundColor} color={getColor(status)?.textColor} />
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.id,
        header: 'Action',
        size: 50,
        enableSorting: false,
        enableGlobalFilter: true,
        Cell: (row) => {
        
          return (
            <div>
             <CustomButton onClick={()=>navigate(`/home/loans/${row?.renderedCellValue}`)} color={COLORS.darkgreen} text='View' variant='contained' endIcon={<VisibilityOutlinedIcon/>}/>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: ['all loans',{...params,page:pagination.pageIndex+1,per_page:pagination.pageSize}] });
  }, [params,token, queryClient,pagination]);

  return (
    <div style={{width:'100%',maxWidth:'100%'}}>
      <CommonTable
        columns={columns}
        data={loans?.results ?? []}
        hasSelectAll={false}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        searchParams={params}
        setSearchParams={setParams}
        searchOptions={searchParamsOptions}
        setPagination={setPagination}
        pagination={pagination}
        totalItems={loans?.totalItems}
        tableTitle='List of all loans'
        hideFilter={false}
      />
    </div>
  );
}
